import { formatDate, formatNumber } from '../../utils/constant';
import { shortenedTitle } from '../../utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { getJobs, getJobsGraphDetails } from '../../store/datasetSlice';
import Pagination from "react-js-pagination";
import { ShimmerTable } from 'react-shimmer-effects';
import { useParams } from 'react-router-dom';
import DataNotFound from '../DataNotFound';
import { updateFilter } from '../../store/commonSlice';
import Charts from '../Charts/Charts';

function Jobs() {
  const dispatch = useAppDispatch();
  const { companyId } = useParams();

  const [list, setList] = useState({ data: [], current_page: 1, per_page: 10, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const { jobs, jobsGraphDetails } = useSelector(({ dataset }) => dataset);
  const { filter ,refresh } = useSelector(({ common }) => common);
  const [seriesData, setSeriesData] = React.useState([]);

  const query = useMemo(() => ({
    companyId: filter?.company?._id || companyId || '',
    from_date: filter?.from_date || '',
    to_date: filter?.to_date || '',
    month: filter?.month || '',
    year: filter?.year || '',
    page: activePage,
    limit: 10
  }), [refresh,companyId, activePage]);

  useEffect(() => {

    dispatch(getJobs(query));
    dispatch(getJobsGraphDetails(query));
  }, [dispatch, refresh,filter?.year,filter?.month,activePage]);

  useEffect(() => {
    setActivePage(1)
  }, [,])

  useEffect(() => {
    setList(jobs?.data);
  }, [jobs]);

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber)
  }

  const xAxis = {
    categories: jobsGraphDetails?.data?.xAxis,
    title: { text: "Keywords" }
  }
  const yAxis = {
    title: { text: "Numbers Of Job Title" },
  }
  const series = jobsGraphDetails?.data?.series
  const pointFormatter = function () {
    return `<span style="color:{point.color}">\u25CF</span>  Jobs : <b> ${formatNumber(this?.y)}</b><br/>`;
  }
  React.useEffect(() => {
    if (series) {
      setSeriesData(
        series.map((sr) => ({
          name: sr?.name,
          data: sr?.data,
          marker: {
            enabled: false,
          },
        }))
      );
    }
  }, [series]);
  return (
    <section className="mt-5">
      { jobs?.data?.data?.length > 0 &&
      <div className="row justify-content-end mb-5 bg-white">
        <div className="col-1 mt-2">
          <select onChange={(e) => dispatch(updateFilter({ ...filter, month: e.target?.value }))} name="year" id="" className='form-control'>
            <option value={''}>Month</option>
            <option value={1}>Jan</option>
            <option value={2}>Feb</option>
            <option value={3}>March</option>
            <option value={4}>Apr</option>
            <option value={5}>May</option>
            <option value={6}>Jun</option>
            <option value={7}>July</option>
            <option value={8}>Aug</option>
            <option value={9}>Sept</option>
            <option value={10}>Oct</option>
            <option value={11}>Nov</option>
            <option value={12}>Dec</option>
          </select>
        </div>
        <div className="col-1 mt-2">
          <select name="year" onChange={(e) => dispatch(updateFilter({ ...filter, year: e.target?.value }))} id="" className='form-control'>
            <option value={''}>Year</option>
            <option value={'2021'}>2021</option>
            <option value={'2022'}>2022</option>
            <option value={'2023'}>2023</option>
            <option value={'2024'}>2024</option>
          </select>
        </div>
        <div className="col-12">
          <Charts
            type={'line'}
            XAxis={xAxis || []}
            YAxis={yAxis || []}
            showLegend={true}
            seriesData={seriesData}
            pointFormatter={pointFormatter}
          />
        </div>
      </div>
      }
      {
        jobs?.loading ? <ShimmerTable row={10} col={7} /> :

          <div className="card card-border mb-4 table_layout2">
            <div className="position-relative">
              <div className="table-responsive mt-0">
                <table className="table-bordered mb-0 table">
                  <thead className="">
                    <tr>
                      <th scope="col">Organization Name</th>
                      <th scope="col" style={{ width: "20%" }}>Title</th>
                      <th scope="col">Work Mode</th>
                      <th scope="col" style={{ width: "15%" }}>Posted On</th>
                      {/* <th scope="col" style={{ width: "15%" }} >Expired On</th> */}
                      <th scope="col" style={{ width: "40%" }}>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      list?.data?.map((data, index) => {
                        return (
                          <tr key={'job-' + index}>
                            <td>{data?.company?.name || '--'}</td>
                            <td>{data?.job_title || '--'}</td>
                            <td>{data?.job_is_remote ? 'WFH' : 'WFO'}</td>
                            <td>{formatDate(data?.job_posted_at_datetime_utc) || '--'}</td>
                            {/* <td>{formatDate(data?.job_offer_expiration_datetime_utc) || '--'}</td> */}
                            <td><span dangerouslySetInnerHTML={{ __html: shortenedTitle(data?.job_description, 200) }} title={data?.job_description}></span></td>
                          </tr>
                        )
                      })
                    }
                    {
                      (jobs?.data?.data?.length <= 0 && !jobs?.loading) && <tr ><td colSpan={5} align='center'><DataNotFound title="No results" description="Unfortunately, no related job could be found." /></td></tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>

      }

      {
        list?.data?.length > 0 &&
        <div className='mt-4'>
          <Pagination
            activePage={activePage}
            itemsCountPerPage={list?.per_page || 0}
            totalItemsCount={list?.total || 0}
            pageRangeDisplayed={5}
            itemClass="page-item"
            linkClass="page-link"
            onChange={handlePageChange}

          />
        </div>
      }
    </section>
  );
};

export default Jobs;
