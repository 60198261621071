import { PayloadAction, createSlice, } from "@reduxjs/toolkit";
import { fetcherGet, fetcherPost, fetcherPut } from "../utils/fetcher";
import { handleAccessShow, handleNavigation, setIsFormReset, setLoader, setServerError, setSuccessMessage } from "./commonSlice";
import { AppDispatch } from "./store";
import { toast } from "react-toastify";

// const TOKEN = "access_token";

const initialState = {
    user: {},
    isLoggedIn: true,
    isVerified: true,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUserDetails: (state, action: PayloadAction<string, any>) => {
            state.user = action.payload;
        },
        setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        },
        setIsVerified: (state, action: PayloadAction<boolean>) => {
            state.isVerified = action.payload;
        },
    },
});

export const { setUserDetails, setIsLoggedIn, setIsVerified } = authSlice.actions;
export default authSlice.reducer;

// export const checkIsAuthenticated = () => {
//     return isLoggedIn 
// };

// export const getAccessToken = () => localStorage.getItem(TOKEN);

// export const storeAccessToken = (token: string) => {
//     localStorage.setItem(TOKEN, token);
// };

// export const removeAccessToken = () => {
//     localStorage.removeItem(TOKEN);
// };
// export const checkUserToken = () => {
//     const token = getAccessToken();
//     if (token === '' || token === null) {
//         setIsLoggedIn(false);
//     } else {
//         setIsLoggedIn(true);
//     }
// }

export function signUp(data: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        dispatch(setServerError(""))
     await fetcherPost(`signup`, data)
            .then((response: any) => {
                if (response.status === 201) {
                    dispatch(setUserDetails(response.user));
                    toast.success(response.message)
                    dispatch(setSuccessMessage(response.message))
                    // dispatch(setSuccessMessage("We have sent mail, verify your account."))
                    dispatch(setIsFormReset(true))
                    // dispatch(handleNavigation('/access-form'))
                    dispatch(handleAccessShow(true));
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data.message || error?.message)
                
             }).finally(() => {
                dispatch(setLoader(false));
            });
    };
}

export function signIn(data: any) {
    
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        dispatch(setServerError(""))
        return new Promise((resolve, reject) => {
            fetcherPost(`sign-in`, data)
            .then((response: any) => {
                if (response?.status === 200) {
                    dispatch(setIsLoggedIn(true))
                    dispatch(setUserDetails(response?.user));
                    localStorage.setItem('is_loggedin', "1");
                    dispatch(setIsVerified(response?.user?.is_verified))
                    if(response?.user?.is_verified !==1 ){
                        dispatch(handleAccessShow(true));
                        toast.error(response.message)
                        reject(response)
                    }else if(response?.user?.mobile_number ===undefined){
                        dispatch(handleNavigation('/dashboard'))
                        toast.error(response)
                        reject(response)
                    }else{
                        dispatch(setSuccessMessage(response?.message))
                        toast.success(response)
                        resolve(response)
                    }
                    
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data.message || error?.response?.message)                
            }).finally(() => {
                dispatch(setLoader(false));
            });
        })
      
    };
}

export function getUserDetails() {
    return async (dispatch: AppDispatch) => {
        // dispatch(setLoader(true));
     await  fetcherGet(`users/get-current-user`)
            .then((response: any) => {
                if (response.status === 200) {
                    console.log("response",response)
                    dispatch(setUserDetails(response?.data));
                    localStorage.setItem('is_loggedin', "1");
                    if(response?.data.is_verified !== 1 ){
                        dispatch(handleAccessShow(true));
                    }
                    if(response?.data.user?.mobile_number === undefined  && response?.data.user?.is_verified === 1){
                        dispatch(handleNavigation('/dashboard'))
                    }

                }else{
                    localStorage.setItem('is_loggedin', "0");

                }
            })
            .catch((error) => {
                localStorage.setItem('is_loggedin', "0");
                if (error?.response?.status === 400 || error?.response?.status === 422) {
                    dispatch(setServerError(error?.response?.data?.message))
                } else if (error?.response?.status === 401) {
                    dispatch(setIsLoggedIn(false))
                    dispatch(setServerError("Unauthenticated"))

                }
            }).finally(() => {
                // dispatch(setLoader(false));
            });
    };
}

export function signOut() {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
       await fetcherPost(`signout`, [])
            .then((response: any) => {
                if (response?.message) {
                    dispatch(setIsLoggedIn(false))
                    localStorage.setItem('is_loggedin', "0");
                    dispatch(setUserDetails(null))
                    dispatch(handleNavigation('/sign-in'))

                }
            })
            .catch((error) => {
                if (error?.response?.status === 400 || error?.response?.status === 422) {
                    dispatch(setServerError(error?.response?.data?.message))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoader(false));
            });
    };
}

export function changePassword(data: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        dispatch(setServerError(""))
        dispatch(setIsFormReset(true))
      await  fetcherPost(`change-password`, data)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setSuccessMessage(response.message))
                    dispatch(setIsFormReset(true))
                } else {
                    dispatch(setServerError(response.error))
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400 || error?.response?.status === 422) {
                    dispatch(setServerError(error?.response?.data?.message))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoader(false));
            });
    };
}


export function forgotPassword(data: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        dispatch(setServerError(""))

     await fetcherPost(`forgot-password`, data)
            .then((response: any) => {
                if (response?.status) {
                    toast.success(response.message) 
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || error?.message ) 
             }).finally(() => {
                dispatch(setLoader(false));
            });
    };
}

export function updatePassword(data: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        dispatch(setServerError(""))

        await fetcherPost(`update-password`, data)
            .then((response: any) => {
                if (response.status === 200) {
                    toast.success(response.message) 
                    dispatch(handleNavigation('/sign-in'))
                } else {
                    toast.success(response.error) 
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || error?.message)                
            }).finally(() => {
                dispatch(setLoader(false));
            });
    };
}

export function verifyAccount(token: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        dispatch(setServerError(""))
        await fetcherGet(`verification/${token}`)
            .then((response: any) => {
                if (response?.status === 200) {
                    dispatch(setUserDetails(response?.data));
                    toast.success(response.message)
                    // dispatch(handleNavigation('/sign-in'))

                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || error?.message)                

            }).finally(() => {
                dispatch(setLoader(false));
            });
    };
}

export function updateAccount(data: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        dispatch(setServerError(""))
        await fetcherPut(`update-user`, data)
            .then((response: any) => {
                if (response?.status === 200) {
                    toast.success(response.message) 
                    dispatch(handleAccessShow(false))
                    dispatch(handleNavigation('/sign-in'))

                }
            })
            .catch((error) => {
                if (error?.response?.status === 400 || error?.response?.status === 422) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoader(false));
            });
    };
}

export function sendAccessCodeLink(data: any) {
    return (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        dispatch(setServerError(""));
        return new Promise((resolve, reject) => {
            fetcherPost(`resend-otp`, data)
                .then((response: any) => {
                    if (response.status === 200) {
                        toast.success(response.message);
                        resolve(response);
                    } else {
                        const error = new Error('Unexpected response status');
                        reject(error);
                    }
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message || error?.message);
                    reject(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        });
    };
}


export function verifyOtp(data: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        dispatch(setServerError(""))
       await fetcherPost(`verify-otp`, data)
            .then((response: any) => {
                if (response.status === 200) {
                    // dispatch(setUserDetails(response?.user));
                    toast.success(response.message)
                    // dispatch(handleNavigation('/dashboard'))
                    dispatch(handleAccessShow(false))
                    dispatch(handleNavigation('/sign-in'))
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || error?.message)
             }).finally(() => {
                dispatch(setLoader(false));
            });
    };
}