import React, { useEffect } from "react";
import Layout from "../../layouts/dashboard/Layout";
import DynamicMeta from "../../component/DynamicMeta";
import { useSelector } from "react-redux";
import { setPageTitle } from "../../store/commonSlice";
import { getNews, getPatents, getDeals } from "../../store/datasetSlice";
import { shortenedTitle } from "../../utils";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { Link } from "react-router-dom";
import { ShimmerTable } from 'react-shimmer-effects';

const Dashboard = () => {

    const dispatch = useAppDispatch();
    const { patents, deals, news } = useSelector(({ dataset }) => dataset);
    const currentYear = new Date().getFullYear();
    const { filter } = useSelector(({ common }) => common);

    useEffect(() => {
        let query = { companyId: '', keyword: filter?.keyword, year: '', page: 1, limit: 4 };
        dispatch(setPageTitle("NeoImpact - Dasboard"))
        dispatch(getNews(query));
        dispatch(getPatents(query));
        dispatch(getDeals(query));
    }, [dispatch])
    return (
        <>
            <DynamicMeta />
            
            <Layout >
                <div className="hk-pg-wrapper pb-10">
                    <div className="container-fluid px-4">
                        <div className="hk-pg-body pt-2">
                            <section>
                                <div className="row justify-content-center">
                                    <div className="col-lg-9 text-center">
                                        <h2 className="text_purple fw-semibold  pt-17">Best-in-class ESG Intelligence SaaS Platform
                                        </h2>
                                        <p className="text_purple font-s-18">NeoImpact’s ESG platform runs on a comprehensive set of ESG fundamental and alternative data sets designed to provide robust and real-time ESG insights and analytics.</p>
                                    </div>
                                    <div className="col-lg-8 mt-3 mt-lg-8">
                                        <form className="homesearch" action="/search">
                                            <input type="text" name="search" placeholder="Find ESG Disclosure, Regulations, Patents, Investments and many more..." className="example" />
                                            <input type="submit" value="Search" />
                                        </form>
                                    </div>
                                </div>
                                {/* <div className="row my-5 mt-lg-8 ">
                                    <div className="col-lg-4">
                                        <div className="news_sec">
                                            <div className="border p-3 text-center news_hadding_1"><h4 className="fw-semibold text-white mb-0">News</h4></div>
                                            {news?.loading
                                                ? <ShimmerTable row={5} col={1} />
                                                : news?.data?.data?.map((News: any, index: number) => {
                                                    return (
                                                        <div className={`border px-3 py-2 ${index % 2 === 0 ? 'bg_color_1' : 'bg-white'}`} key={`news-${index}`}>
                                                            <p className="text-black fw-semibold font-s-16 mb-1" dangerouslySetInnerHTML={{ __html: shortenedTitle(News?.title, 40) }}></p>
                                                            <span dangerouslySetInnerHTML={{ __html: shortenedTitle(News?.summary, 50) }}></span>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {(!news?.loading && news?.data?.data?.length === 0) && (
                                                <div className={`border px-3 py-2 bg-white text-center`}>
                                                    <span >No News Found</span>
                                                </div>
                                            )
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt-4 mt-lg-0">
                                        <div className="news_sec">
                                            <div className="border p-3 text-center news_hadding_2"><h4 className="fw-semibold text-white mb-0">Patents</h4></div>
                                            {patents?.loading
                                                ? <ShimmerTable row={5} col={1} />
                                                : patents?.data?.data?.map((patent: any, index: number) => {
                                                    return (
                                                        <div className={`border px-3 py-2 ${index % 2 === 0 ? 'bg_color_1' : 'bg-white'}`} key={`patent-${index}`}>
                                                            <p className="text-black fw-semibold font-s-16 mb-1" dangerouslySetInnerHTML={{ __html: shortenedTitle(patent?.patent_title, 40) }}></p>
                                                            <span dangerouslySetInnerHTML={{ __html: shortenedTitle(patent?.patent_abstract, 50) }}></span>

                                                        </div>
                                                    )
                                                })
                                            }
                                            {(!patents?.loading && patents?.data?.data?.length === 0) && (
                                                <div className={`border px-3 py-2 bg-white text-center`}>
                                                    <span >No Patents Found</span>
                                                </div>
                                            )
                                            }

                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt-4 mt-lg-0">
                                        <div className="news_sec">
                                            <div className="border p-3 text-center news_hadding_1"><h4 className="fw-semibold text-white mb-0">Deals</h4></div>
                                            {deals?.loading
                                                ? <ShimmerTable row={5} col={1} />
                                                : deals?.data?.fund?.data?.map((deal: any, index: number) => {
                                                    return (
                                                        <div className={`border px-3 py-2 ${index % 2 === 0 ? 'bg_color_1' : 'bg-white'}`} key={`deals-${index}`}>
                                                            <p className="text-black fw-semibold font-s-16 mb-1" dangerouslySetInnerHTML={{ __html: shortenedTitle(deal?.identifier?.value, 40) }}></p>
                                                            <span dangerouslySetInnerHTML={{ __html: shortenedTitle(deal?.short_description, 50) }}></span>
                                                        </div>
                                                    )
                                                })
                                            }

                                            {(!deals?.loading && deals?.data?.data?.length === 0) && (
                                                <div className={`border px-3 py-2 bg-white text-center`}>
                                                    <span >No  Deals Found</span>
                                                </div>
                                            )
                                            }
                                        </div>
                                    </div>
                                </div> */}
                            </section>
                        </div>
                    </div>
                    <div className="hk-footer">
                        <footer className="container-xxl footer">
                            <div className="row">
                                <div className="col-xl-8">
                                    <p className="footer-text text-center"><span className="copy-text">Copyright ©2024 
                                       NeoImpact.</span><Link className="" title="Privacy policy"
                                            to={"https://neoimpact.com/privacy-policy/"} target="_blank">Privacy
                                            policy</Link><span className="footer-link-sep">|</span><Link className=""
                                                title="Terms &amp; Conditions"
                                                to={"https://neoimpact.com/terms-conditions/"} target="_blank">Terms
                                            &amp; Conditions</Link></p>
                                </div>
                                <div className="col-xl-4"></div>
                            </div>
                        </footer>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Dashboard;

