import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetcherGet } from "../utils/fetcher";
import { setServerError } from "./commonSlice";
import { AppDispatch } from "./store";


const initialState = {
    sectorDetail: { data: {}, loading: false },
    sectors: { data: [], loading: false },
    sectorsDropdown: { data: [], loading: false },
    subSectorsDropdown: { data: [], loading: false },
    esgScore: { data: { all_score: 0, e_score: 0, g_score: 0, is_publish: 0, research_id: 0, s_score: 0 }, loading: false },
};

const sectorsSlice = createSlice({
    name: "sector",
    initialState,
    reducers: {
        setSectorDetails: (state, action: PayloadAction<any>) => {
            state.sectorDetail = action.payload;
        },
        setSectors: (state, action: PayloadAction<any>) => {
            state.sectors = action.payload;
        },
        setSectorsDropdown: (state, action: PayloadAction<any>) => {
            state.sectorsDropdown = action.payload;
        },
        setSubSectorsDropdown: (state, action: PayloadAction<any>) => {
            state.subSectorsDropdown = action.payload;
        },
        setEsgScore: (state, action: PayloadAction<any>) => {
            state.esgScore = action.payload;
        },
        setLoading: (state, action: PayloadAction<{ key: string; loading: boolean }>) => {
            state[action.payload.key].loading = action.payload.loading;
        }
    },
});

export const { setSectorDetails, setSectors, setLoading, setSectorsDropdown, setSubSectorsDropdown, setEsgScore } = sectorsSlice.actions;
export default sectorsSlice.reducer;

export function getSubSectorDropdown({ q, parentId, companyId, limit }) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'subSectorsDropdown', loading: true }));
        fetcherGet(`sub-industry-dropdown?q=${q}&company_id=${companyId}&parent_id=${parentId}&limit=${limit}`)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setSubSectorsDropdown({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoading({ key: 'subSectorsDropdown', loading: false }));
            });
    };
}

export function getSectorDropdown({ q, companyId, limit }) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'sectorsDropdown', loading: true }));
        fetcherGet(`parent-industry-dropdown?q=${q}&company_id=${companyId}&limit=${limit}`)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setSectorsDropdown({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoading({ key: 'sectorsDropdown', loading: false }));
            });
    };
}

export function getSectors({ q, limit }) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'sectors', loading: true }));
        fetcherGet(`industries`)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setSectors({ data: response?.data?.data, loading: false }));
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoading({ key: 'sectors', loading: false }));
            });
    };
}

export function getSectorsDetails(sectorId: any) {
    let currentYear = new Date().getFullYear() -1;
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'sectorDetail', loading: true }));
        dispatch(setLoading({ key: 'esgScore', loading: true }));
        fetcherGet(`industry/${sectorId}?year=${currentYear}`)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setSectorDetails({ data: response?.data[0], loading: false }));
                    dispatch(setEsgScore(response?.esgScore))
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoading({ key: 'sectorDetail', loading: false }));
                dispatch(setLoading({ key: 'esgScore', loading: false }));

            });
    };
}
