import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setPageTitle } from '../../store/commonSlice';
import DynamicMeta from '../../component/DynamicMeta';
import { changePassword } from '../../store/authSlice';
import { ButtonSpinner } from '../../component/Button';
import { useAppDispatch } from '../../hooks/useAppDispatch';

function ChangePassword({ activeTab }) {
    const dispatch = useAppDispatch();

    // Set page title using useEffect to avoid calling dispatch during rendering
    useEffect(() => {
        dispatch(setPageTitle("NeoImpact - Sign In"));
    }, [dispatch]);

    const { loader, serverError, successMessage } = useSelector(({ common }) => common);

    const defaultValues = {
        current_password: '',
        new_password: '',
        new_password_confirmation: ''
    };
    const [detail, setDetails] = useState(defaultValues);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        if (detail?.current_password === "") {
            setErrorMessage('Please enter current password');
            return;
        }
        if (detail?.new_password === "") {
            setErrorMessage('Please enter new password');
            return;
        }
        if (detail?.current_password === detail?.new_password) {
            setErrorMessage('Old password and current password should be different');
            return;
        }
        if (detail?.new_password_confirmation === "") {
            setErrorMessage('Please enter confirm password');
            return;
        }
        if (detail?.new_password_confirmation !== detail?.new_password) {
            setErrorMessage('New password  and  confirm password');
            return;
        }
        setErrorMessage("")
        dispatch(changePassword(detail))
            .then((res : any) => {
                console.log("res",res);
                if(res?.status === 200){
                    setDetails(defaultValues);
                }
            }).catch(() => {

            });
    };


    const handleOnChange = (event : any) => {
        const { name, value, type, checked } = event.target;
        setDetails((oldDetail) => ({
            ...oldDetail,
            [name]: type === 'checkbox' ? checked : value
        }));
    };



    return (
        <div className={`tab-pane fade ${activeTab === 'change-password' ? 'show active' : ''}`} id="change_password_tab" role="tabpanel" aria-labelledby="change_password_tab-tab">
            <DynamicMeta />
            <div className="col-lg-12">
                <div className="">
                    <div className="">
                        <div className="row">
                            <form onSubmit={handleSubmit}>

                                <div className="row gx-3">
                                    <div className="col-lg-4 col-sm-6">
                                        {(errorMessage || serverError) && <p className='text-danger'>{errorMessage ? errorMessage : serverError}</p>}
                                        {successMessage && <p className='text-success mb-3'>{successMessage}</p>}

                                        <div className="mb-4">
                                            <label className="text_purple2 font-s-12 fw-semibold mb-1" >Current Password</label>
                                            <input className="form-control bg-transparent" type="password" name="current_password" placeholder="Current Password" value={detail?.current_password} onChange={handleOnChange} />
                                        </div>

                                        <div className="mb-4">
                                            <label className="text_purple2 font-s-12 fw-semibold mb-1" >New Password</label>
                                            <input className="form-control bg-transparent form-control-user" name="new_password" type="password" placeholder="New Password" value={detail?.new_password} onChange={handleOnChange} />
                                        </div>

                                        <div className="mb-4">
                                            <label className="text_purple2 font-s-12 fw-semibold mb-1" >Confirm Password</label>
                                            <input className="form-control bg-transparent form-control-user" name="new_password_confirmation" type="password" placeholder="Confirm Password" value={detail?.new_password_confirmation} onChange={handleOnChange} />
                                        </div>
                                        <button type="submit" className="btn btn-primary-horizon" disabled={loader} title='Update'><ButtonSpinner /> Update</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ChangePassword;