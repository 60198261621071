
import React, { useEffect, useState } from 'react';
import { Link , useNavigate} from 'react-router-dom';
import Layout from "../../layouts/dashboard/Layout";
import DynamicMeta from "../../component/DynamicMeta";

function Portfolio() {

    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/company/locations');
    };

  return (
    <>
    <DynamicMeta />
    <Layout >
        <div className="hk-pg-wrapper pb-10">
            <div className="container-fluid px-4">

                
                


                <div className="hk-pg-body pt-2 pb-14">

                <header className="profile-header">
                        <ul className="nav nav-line nav-tabs nav-icon nav-light h-100 d-flex">
                            <li className="nav-item"><a className="d-flex align-items-center nav-link h-100 active"
                                     title="portfolio"
                                    href="/portfolio"><span
                                        className="nav-link-text">Portfolio</span></a></li>
                            <li className="nav-item"><a className="d-flex align-items-center nav-link h-100 "
                                     title="Watchlist"
                                    href="/watchlist"><span
                                        className="nav-link-text">Watchlist</span></a></li>
                            <li className="nav-item"><a className="d-flex align-items-center nav-link h-100  "
                                     href="/recentvisit"><span
                                        className="nav-link-text">Recent Visited</span></a></li>
                            
                        </ul>
                    </header>

                    <section className="my_portfolio">
                        <div className="row mt-3">
                            <div className="col-lg-9">
                                <h1 className="text-black fw-semibold mb-3 h4">My Portfolio</h1>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-5 col-12">
                                                <div className="nav flex-column nav-pills border bg-white rounded" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                    <form className="w-100">
                                                        <div className="search-box">
                                                            <input className="search-input" type="text" placeholder="Search Here..." />
                                                            <button className="search-btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="" className="bi bi-search" viewBox="0 0 16 16">
                                                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                                                </svg>
                                                            </button>
                                                        </div>

                                                    </form>
                                                    <button className="nav-link active" id="myportfolio-tab-1"  onClick={handleRedirect} >
                                                        <div className="compeny_logo">A</div>Musandam Power Co.</button>
                                                    <button className="nav-link"  onClick={handleRedirect} >
                                                        <div className="compeny_logo">B</div>Dhofar Insurance</button>
                                                    <button className="nav-link"  onClick={handleRedirect} >
                                                        <div className="compeny_logo">C</div>UBAR Hotels & Resorts</button>
                                                    <button className="nav-link"  onClick={handleRedirect} >
                                                        <div className="compeny_logo">D</div>National Detergent Company</button>
                                                    <button className="nav-link"  onClick={handleRedirect} >
                                                        <div className="compeny_logo">E</div>Oman & Emirates Investment Holdings</button>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-7 col-12">
                                                <div className="tab-content mt-3 mt-md-0" id="v-pills-tabContent">
                                                    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="myportfolio-tab-1" tabIndex={0}>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                
                                                                <div className="row">
                                                                    <div className="col-12 col-sm-4 col-md-4">
                                                                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                                                            <div className="d-flex">
                                                                                <div className="feature_style_1_icon me-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-database-add" viewBox="0 0 16 16">
                                                                                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0" />
                                                                                        <path d="M12.096 6.223A5 5 0 0 0 13 5.698V7c0 .289-.213.654-.753 1.007a4.5 4.5 0 0 1 1.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-.813-.927Q8.378 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.6 4.6 0 0 1 0-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10q.393 0 .774-.024a4.5 4.5 0 0 1 1.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777M3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4"
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <h5 className="text_color_1 fw-bold fs-3 mb-0">240+</h5></div>
                                                                                        <div className="text-end ms-2">
                                                                                            <Link to={'#'} className="dashboard_arow_1">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="border_bottom_custom_1 my-1"></div>

                                                                                    <p className="fw-medium text-black">Patents</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-4 col-md-4">
                                                                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                                                            <div className="d-flex">
                                                                                <div className="feature_style_1_icon me-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-briefcase" viewBox="0 0 16 16">
                                                                                        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5m1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0M1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5"
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <h5 className="text_color_1 fw-bold fs-3 mb-0">15+</h5></div>
                                                                                        <div className="text-end ms-2">
                                                                                            <Link to={'#'} className="dashboard_arow_1">


                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="border_bottom_custom_1 my-1"></div>

                                                                                    <p className="fw-medium text-black">ESG Jobs</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-4 col-md-4">
                                                                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                                                            <div className="d-flex">
                                                                                <div className="feature_style_1_icon me-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard2-check" viewBox="0 0 16 16">
                                                                                        <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z" />
                                                                                        <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z" />
                                                                                        <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <h5 className="text_color_1 fw-bold fs-3 mb-0">10+</h5></div>
                                                                                        <div className="text-end ms-2">
                                                                                            <Link to={'#'} className="dashboard_arow_1">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="border_bottom_custom_1 my-1"></div>

                                                                                    <p className="fw-medium text-black"> Regulations </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-12">
                                                                        <div className="border border-bottom-0 bg-white rounded-top p-3">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <h5 className="text-black fw-semibold mb-0">Regulations</h5>
                                                                                <Link to={'#'} className="btn btn-sm btn-soft-primary">View All</Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="style_2 border bg-white rounded-bottom">
                                                                            <div className="table-responsive">
                                                                                <table className="table mb-0">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>ISSUING THE LAW ON CONSERVATION OF THE ENVIRONMENT AND PREVENTION OF POLLUTION
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="text-end">
                                                                                                    <Link to={'#'} className="dashboard_arow_2">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                                                                        </svg>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Issuing The Law On Nature Reserves And Wildlife Conservation.
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="text-end">
                                                                                                    <Link to={'#'} className="dashboard_arow_2">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                                                                        </svg>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>REGULATIONS FOR THE MANAGEMENT OF HAZARDOUS WASTE
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="text-end">
                                                                                                    <Link to={'#'} className="dashboard_arow_2">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                                                                        </svg>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-12">
                                                                        <div className="border border-bottom-0 bg-white rounded-top p-3">
                                                                            <h5 className="text-black fw-semibold mb-0">Tags</h5>
                                                                        </div>
                                                                        <div className="border bg-white rounded-bottom p-3">
                                                                            <ul className="d-flex flex-wrap justify-content-between grid gap-3">
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Social</a></li>
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Governance</a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="myportfolio-tab-2" tabIndex={0}><div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="mt-2 mb-lg-2 text-end"><a href="country.html" className="btn_three_outline">View Company</a></div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-12 col-sm-4 col-md-4">
                                                                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                                                            <div className="d-flex">
                                                                                <div className="feature_style_1_icon me-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-database-add" viewBox="0 0 16 16">
                                                                                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0" />
                                                                                        <path d="M12.096 6.223A5 5 0 0 0 13 5.698V7c0 .289-.213.654-.753 1.007a4.5 4.5 0 0 1 1.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-.813-.927Q8.378 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.6 4.6 0 0 1 0-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10q.393 0 .774-.024a4.5 4.5 0 0 1 1.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777M3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4"
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <h5 className="text_color_1 fw-bold fs-3 mb-0">240+</h5></div>
                                                                                        <div className="text-end ms-2">
                                                                                            <Link to={'#'} className="dashboard_arow_1">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="border_bottom_custom_1 my-1"></div>

                                                                                    <p className="fw-medium text-black">Patents</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-4 col-md-4">
                                                                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                                                            <div className="d-flex">
                                                                                <div className="feature_style_1_icon me-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-briefcase" viewBox="0 0 16 16">
                                                                                        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5m1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0M1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5"
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <h5 className="text_color_1 fw-bold fs-3 mb-0">15+</h5></div>
                                                                                        <div className="text-end ms-2">
                                                                                            <Link to={'#'} className="dashboard_arow_1">


                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="border_bottom_custom_1 my-1"></div>

                                                                                    <p className="fw-medium text-black">ESG Jobs</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-4 col-md-4">
                                                                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                                                            <div className="d-flex">
                                                                                <div className="feature_style_1_icon me-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard2-check" viewBox="0 0 16 16">
                                                                                        <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z" />
                                                                                        <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z" />
                                                                                        <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <h5 className="text_color_1 fw-bold fs-3 mb-0">10+</h5></div>
                                                                                        <div className="text-end ms-2">
                                                                                            <Link to={'#'} className="dashboard_arow_1">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="border_bottom_custom_1 my-1"></div>

                                                                                    <p className="fw-medium text-black"> Regulations </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-12">
                                                                        <div className="border border-bottom-0 bg-white rounded-top p-3">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <h5 className="text-black fw-semibold mb-0">Regulations</h5>
                                                                                <a href="#" className="btn btn-sm btn-soft-primary">View All</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="style_2 border bg-white rounded-bottom">
                                                                            <div className="table-responsive">
                                                                                <table className="table mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col">Regulations</th>
                                                                                            <th scope="col"></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>Title</td>
                                                                                            <td>
                                                                                                <div className="text-end">
                                                                                                    <Link to={'#'} className="dashboard_arow_2">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                                                                        </svg>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Title</td>
                                                                                            <td>
                                                                                                <div className="text-end">
                                                                                                    <Link to={'#'} className="dashboard_arow_2">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                                                                        </svg>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Title</td>
                                                                                            <td>
                                                                                                <div className="text-end">
                                                                                                    <Link to={'#'} className="dashboard_arow_2">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                                                                        </svg>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-12">
                                                                        <div className="border border-bottom-0 bg-white rounded-top p-3">
                                                                            <h5 className="text-black fw-semibold mb-0">Tags</h5>
                                                                        </div>
                                                                        <div className="border bg-white rounded-bottom p-3">
                                                                            <ul className="d-flex flex-wrap justify-content-between grid gap-3">
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Social</a></li>
                                                                                <li><a href="#" className="tag_1">Governance</a></li>
                                                                                <li><a href="#" className="tag_1">Social</a></li>
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                    <div className="tab-pane fade" id="v-pills-disabled" role="tabpanel" aria-labelledby="myportfolio-tab-3" tabIndex={0}><div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="mt-2 mb-lg-2 text-end"><a href="country.html" className="btn_three_outline">View Company</a></div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-12 col-sm-4 col-md-4">
                                                                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                                                            <div className="d-flex">
                                                                                <div className="feature_style_1_icon me-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-database-add" viewBox="0 0 16 16">
                                                                                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0" />
                                                                                        <path d="M12.096 6.223A5 5 0 0 0 13 5.698V7c0 .289-.213.654-.753 1.007a4.5 4.5 0 0 1 1.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-.813-.927Q8.378 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.6 4.6 0 0 1 0-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10q.393 0 .774-.024a4.5 4.5 0 0 1 1.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777M3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4"
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <h5 className="text_color_1 fw-bold fs-3 mb-0">240+</h5></div>
                                                                                        <div className="text-end ms-2">
                                                                                            <Link to={'#'} className="dashboard_arow_1">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="border_bottom_custom_1 my-1"></div>

                                                                                    <p className="fw-medium text-black">Patents</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-4 col-md-4">
                                                                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                                                            <div className="d-flex">
                                                                                <div className="feature_style_1_icon me-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-briefcase" viewBox="0 0 16 16">
                                                                                        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5m1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0M1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5"
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <h5 className="text_color_1 fw-bold fs-3 mb-0">15+</h5></div>
                                                                                        <div className="text-end ms-2">
                                                                                            <Link to={'#'} className="dashboard_arow_1">


                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="border_bottom_custom_1 my-1"></div>

                                                                                    <p className="fw-medium text-black">ESG Jobs</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-4 col-md-4">
                                                                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                                                            <div className="d-flex">
                                                                                <div className="feature_style_1_icon me-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard2-check" viewBox="0 0 16 16">
                                                                                        <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z" />
                                                                                        <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z" />
                                                                                        <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <h5 className="text_color_1 fw-bold fs-3 mb-0">10+</h5></div>
                                                                                        <div className="text-end ms-2">
                                                                                            <Link to={'#'} className="dashboard_arow_1">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="border_bottom_custom_1 my-1"></div>

                                                                                    <p className="fw-medium text-black"> Regulations </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-12">
                                                                        <div className="border border-bottom-0 bg-white rounded-top p-3">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <h5 className="text-black fw-semibold mb-0">Regulations</h5>
                                                                                <a href="#" className="btn btn-sm btn-soft-primary">View All</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="style_2 border bg-white rounded-bottom">
                                                                            <div className="table-responsive">
                                                                                <table className="table mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col">Regulations</th>
                                                                                            <th scope="col"></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>Title</td>
                                                                                            <td>
                                                                                                <div className="text-end">
                                                                                                    <Link to={'#'} className="dashboard_arow_2">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                                                                        </svg>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Title</td>
                                                                                            <td>
                                                                                                <div className="text-end">
                                                                                                    <Link to={'#'} className="dashboard_arow_2">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                                                                        </svg>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Title</td>
                                                                                            <td>
                                                                                                <div className="text-end">
                                                                                                    <Link to={'#'} className="dashboard_arow_2">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                                                                        </svg>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-12">
                                                                        <div className="border border-bottom-0 bg-white rounded-top p-3">
                                                                            <h5 className="text-black fw-semibold mb-0">Tags</h5>
                                                                        </div>
                                                                        <div className="border bg-white rounded-bottom p-3">
                                                                            <ul className="d-flex flex-wrap justify-content-between grid gap-3">
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Social</a></li>
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Social</a></li>
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                    <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="myportfolio-tab-4" tabIndex={0}><div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="mt-2 mb-lg-2 text-end"><a href="country.html" className="btn_three_outline">View Company</a></div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-12 col-sm-4 col-md-4">
                                                                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                                                            <div className="d-flex">
                                                                                <div className="feature_style_1_icon me-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-database-add" viewBox="0 0 16 16">
                                                                                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0" />
                                                                                        <path d="M12.096 6.223A5 5 0 0 0 13 5.698V7c0 .289-.213.654-.753 1.007a4.5 4.5 0 0 1 1.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-.813-.927Q8.378 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.6 4.6 0 0 1 0-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10q.393 0 .774-.024a4.5 4.5 0 0 1 1.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777M3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4"
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <h5 className="text_color_1 fw-bold fs-3 mb-0">240+</h5></div>
                                                                                        <div className="text-end ms-2">
                                                                                            <Link to={'#'} className="dashboard_arow_1">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="border_bottom_custom_1 my-1"></div>

                                                                                    <p className="fw-medium text-black">Patents</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-4 col-md-4">
                                                                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                                                            <div className="d-flex">
                                                                                <div className="feature_style_1_icon me-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-briefcase" viewBox="0 0 16 16">
                                                                                        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5m1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0M1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5"
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <h5 className="text_color_1 fw-bold fs-3 mb-0">15+</h5></div>
                                                                                        <div className="text-end ms-2">
                                                                                            <Link to={'#'} className="dashboard_arow_1">


                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="border_bottom_custom_1 my-1"></div>

                                                                                    <p className="fw-medium text-black">ESG Jobs</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-4 col-md-4">
                                                                        <div className="style_1 border bg-white rounded p-3 mt-4 mt-md-0">
                                                                            <div className="d-flex">
                                                                                <div className="feature_style_1_icon me-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard2-check" viewBox="0 0 16 16">
                                                                                        <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z" />
                                                                                        <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z" />
                                                                                        <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <h5 className="text_color_1 fw-bold fs-3 mb-0">10+</h5></div>
                                                                                        <div className="text-end ms-2">
                                                                                            <Link to={'#'} className="dashboard_arow_1">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="border_bottom_custom_1 my-1"></div>

                                                                                    <p className="fw-medium text-black"> Regulations </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-12">
                                                                        <div className="border border-bottom-0 bg-white rounded-top p-3">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <h5 className="text-black fw-semibold mb-0">Regulations</h5>
                                                                                <a href="#" className="btn btn-sm btn-soft-primary">View All</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="style_2 border bg-white rounded-bottom">
                                                                            <div className="table-responsive">
                                                                                <table className="table mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col">Regulations</th>
                                                                                            <th scope="col"></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>Title</td>
                                                                                            <td>
                                                                                                <div className="text-end">
                                                                                                    <Link to={'#'} className="dashboard_arow_2">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                                                                        </svg>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Title</td>
                                                                                            <td>
                                                                                                <div className="text-end">
                                                                                                    <Link to={'#'} className="dashboard_arow_2">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                                                                        </svg>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Title</td>
                                                                                            <td>
                                                                                                <div className="text-end">
                                                                                                    <Link to={'#'} className="dashboard_arow_2">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                                                                        </svg>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-12">
                                                                        <div className="border border-bottom-0 bg-white rounded-top p-3">
                                                                            <h5 className="text-black fw-semibold mb-0">Tags</h5>
                                                                        </div>
                                                                        <div className="border bg-white rounded-bottom p-3">
                                                                            <ul className="d-flex flex-wrap justify-content-between grid gap-3">
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                    <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="myportfolio-tab-5" tabIndex={0}><div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="mt-2 mb-lg-2 text-end"><a href="country.html" className="btn_three_outline">View Company</a></div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-12 col-sm-4 col-md-4">
                                                                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                                                            <div className="d-flex">
                                                                                <div className="feature_style_1_icon me-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-database-add" viewBox="0 0 16 16">
                                                                                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0" />
                                                                                        <path d="M12.096 6.223A5 5 0 0 0 13 5.698V7c0 .289-.213.654-.753 1.007a4.5 4.5 0 0 1 1.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.5 4.5 0 0 1-.813-.927Q8.378 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.6 4.6 0 0 1 0-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10q.393 0 .774-.024a4.5 4.5 0 0 1 1.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777M3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4"
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <h5 className="text_color_1 fw-bold fs-3 mb-0">240+</h5></div>
                                                                                        <div className="text-end ms-2">
                                                                                            <Link to={'#'} className="dashboard_arow_1">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="border_bottom_custom_1 my-1"></div>

                                                                                    <p className="fw-medium text-black">Patents</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-4 col-md-4">
                                                                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                                                            <div className="d-flex">
                                                                                <div className="feature_style_1_icon me-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-briefcase" viewBox="0 0 16 16">
                                                                                        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5m1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0M1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5"
                                                                                        />
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <h5 className="text_color_1 fw-bold fs-3 mb-0">15+</h5></div>
                                                                                        <div className="text-end ms-2">
                                                                                            <Link to={'#'} className="dashboard_arow_1">


                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="border_bottom_custom_1 my-1"></div>

                                                                                    <p className="fw-medium text-black">ESG Jobs</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-4 col-md-4">
                                                                        <div className="style_1 border bg-white rounded p-3 mt-3 mt-md-0">
                                                                            <div className="d-flex">
                                                                                <div className="feature_style_1_icon me-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard2-check" viewBox="0 0 16 16">
                                                                                        <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z" />
                                                                                        <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z" />
                                                                                        <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div>
                                                                                            <h5 className="text_color_1 fw-bold fs-3 mb-0">10+</h5></div>
                                                                                        <div className="text-end ms-2">
                                                                                            <Link to={'#'} className="dashboard_arow_1">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                                                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="border_bottom_custom_1 my-1"></div>

                                                                                    <p className="fw-medium text-black"> Regulations </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-12">
                                                                        <div className="border border-bottom-0 bg-white rounded-top p-3">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <h5 className="text-black fw-semibold mb-0">Regulations</h5>
                                                                                <a href="#" className="btn btn-sm btn-soft-primary">View All</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="style_2 border bg-white rounded-bottom">
                                                                            <div className="table-responsive">
                                                                                <table className="table mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col">Regulations</th>
                                                                                            <th scope="col"></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>Title</td>
                                                                                            <td>
                                                                                                <div className="text-end">
                                                                                                    <Link to={'#'} className="dashboard_arow_2">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                                                                        </svg>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Title</td>
                                                                                            <td>
                                                                                                <div className="text-end">
                                                                                                    <Link to={'#'} className="dashboard_arow_2">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                                                                        </svg>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Title</td>
                                                                                            <td>
                                                                                                <div className="text-end">
                                                                                                    <Link to={'#'} className="dashboard_arow_2">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                                                                                            <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                                                                                        </svg>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-12">
                                                                        <div className="border border-bottom-0 bg-white rounded-top p-3">
                                                                            <h5 className="text-black fw-semibold mb-0">Tags</h5>
                                                                        </div>
                                                                        <div className="border bg-white rounded-bottom p-3">
                                                                            <ul className="d-flex flex-wrap justify-content-between grid gap-3">
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                                <li><a href="#" className="tag_1">Environment</a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <h1 className="text-black fw-semibold my-3 mt-lg-0 h4">Recent News</h1>
                                <div className="row">
                                    <div className="col-sm-6 col-12 col-lg-12">
                                        <div className="border bg-info bg-opacity-10 rounded p-3">
                                            <h6 className="fw-medium text-black border-bottom border-secondary-subtle py-2 mb-0">Musandam Power Company SAOG Reports Earnings Results for the Half Year Ended June 30, 2024</h6>
                                            <h6 className="fw-medium text-black  border-bottom border-secondary-subtle py-2 mb-0">Musandam Power Company SAOG Approves Board and Committee Changes. </h6>
                                            <h6 className="fw-medium text-black  border-bottom border-secondary-subtle py-2 mb-0">Musandam Power Company SAOG Announces Resignation of Sangmin Jee as Member of the Board of Directors and Other Committees.</h6>
                                            <h6 className="fw-medium text-black  border-bottom border-secondary-subtle py-2 mb-0">Dhofar Insurance Company SAOG Reports Earnings Results for the Half Year Ended June 30, 2024</h6>
                                            <h6 className="fw-medium text-black  border-bottom border-secondary-subtle py-2 mb-0">Dhofar Insurance Company SAOG Reports Earnings Results for the First Quarter Ended March 31, 2024.</h6>
                                            <h6 className="fw-medium text-black  border-bottom border-secondary-subtle py-2 mb-0">Dhofar Insurance Company SAOG Proposes Cash Dividend for the Financial Year Ended 31 December 2023</h6>
                                            <h6 className="fw-medium text-black  border-bottom border-secondary-subtle py-2 mb-0">Ubar Hotels & Resorts SAOG Reports Earnings Results for the Half Year Ended June 30, 2024</h6>
                                            <h6 className="fw-medium text-black  border-bottom border-secondary-subtle py-2 mb-0">Ubar Hotels & Resorts SAOG Reports Earnings Results for the First Quarter Ended March 31, 2024</h6>
                                            <h6 className="fw-medium text-black  border-bottom border-secondary-subtle py-2 mb-0">Ubar Hotels & Resorts SAOG Reports Earnings Results for the Full Year Ended December 31, 2023</h6>
                                            <div className="text-end mt-3"><a href="#" className="text-decoration-underline fw-semibold">Show More news</a></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </Layout>
   </> 
          

  );
};

export default Portfolio;