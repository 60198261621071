
import React, { useEffect, useState } from 'react';
import banner_1 from "../../assets/img/banner_1.svg";
import regional_industry from "../../assets/img/regulations-by-Industry.png";
import regional_growth from "../../assets/img/YOY-growth-regulation.png";
import Layout from "../../layouts/dashboard/Layout";
import DynamicMeta from "../../component/DynamicMeta";

function Regulation() {

  return (
    <>
        <DynamicMeta />
        <Layout >
                    <section  className="banner_1 py-6 rounded-4">
                        <div  className="container">
                            <div  className="row justify-content-center">
                                <div  className="col-lg-6">
                                    <h1  className="text-black fw-semibold mb-3 mt-10 h3 text-white">Computing ESG Regulations Real-time.                                   </h1>
                                    <div  className="pt-4"><a href="/regulation-dashboard"  className="btn_one">Go to Dashboard</a></div>
                                </div>
                                <div  className="col-lg-4">
                                    <img src={banner_1} width="330" />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section  className="industry pt-5">
                        <div  className="row mb-4">
                            <div  className="col-lg-12">
                                <h1  className="text-black fw-bold mb-3 h4">Regulations Overview by Industry </h1>
                                <div  className="companies_filter border bg-white rounded p-4">

                                    <div  className="text-center row row-cols-lg-5">
                                        <div  className="col-lg col-6 col-sm-4">
                                            <div  className="style_1">
                                                <div  className="icon mb-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor"  className="bi bi-shield-lock" viewBox="0 0 16 16">
                                                        <path d="M5.338 1.59a61 61 0 0 0-2.837.856.48.48 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.7 10.7 0 0 0 2.287 2.233c.346.244.652.42.893.533q.18.085.293.118a1 1 0 0 0 .101.025 1 1 0 0 0 .1-.025q.114-.034.294-.118c.24-.113.547-.29.893-.533a10.7 10.7 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.8 11.8 0 0 1-2.517 2.453 7 7 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7 7 0 0 1-1.048-.625 11.8 11.8 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 63 63 0 0 1 5.072.56"
                                                        />
                                                        <path d="M9.5 6.5a1.5 1.5 0 0 1-1 1.415l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99a1.5 1.5 0 1 1 2-1.415" />
                                                    </svg>
                                                </div>
                                                <div  className="content">
                                                    <h5  className="mb-0">Energy</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div  className="col-lg col-6 col-sm-4">
                                            <div  className="style_1">
                                                <div  className="icon mb-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#5BCAF4"  className="bi bi-backpack4" viewBox="0 0 16 16">
                                                        <path d="M4 9.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm1 .5v3h6v-3h-1v.5a.5.5 0 0 1-1 0V10z" />
                                                        <path d="M8 0a2 2 0 0 0-2 2H3.5a2 2 0 0 0-2 2v1c0 .52.198.993.523 1.349A.5.5 0 0 0 2 6.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V6.5a.5.5 0 0 0-.023-.151c.325-.356.523-.83.523-1.349V4a2 2 0 0 0-2-2H10a2 2 0 0 0-2-2m0 1a1 1 0 0 0-1 1h2a1 1 0 0 0-1-1M3 14V6.937q.24.062.5.063h4v.5a.5.5 0 0 0 1 0V7h4q.26 0 .5-.063V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1m9.5-11a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-9a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z"
                                                        />
                                                    </svg>
                                                </div>
                                                <div  className="content">
                                                    <h5  className="mb-0">Utilities</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div  className="col-lg col-6 col-sm-4">
                                            <div  className="style_1">
                                                <div  className="icon mb-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  className="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
                                                        <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293z" />
                                                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                                    </svg>
                                                </div>
                                                <div  className="content">
                                                    <h5  className="mb-0">Materials</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div  className="col-lg col-6 col-sm-4">
                                            <div  className="style_1">
                                                <div  className="icon mb-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  className="bi bi-buildings" viewBox="0 0 16 16">
                                                        <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022M6 8.694 1 10.36V15h5zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5z"
                                                        />
                                                        <path d="M2 11h1v1H2zm2 0h1v1H4zm-2 2h1v1H2zm2 0h1v1H4zm4-4h1v1H8zm2 0h1v1h-1zm-2 2h1v1H8zm2 0h1v1h-1zm2-2h1v1h-1zm0 2h1v1h-1zM8 7h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zM8 5h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zm0-2h1v1h-1z" />
                                                    </svg>
                                                </div>
                                                <div  className="content">
                                                    <h5  className="mb-0">Industrials</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div  className="col-lg col-6 col-sm-4">
                                            <div  className="style_1">
                                                <div  className="icon mb-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  className="bi bi-person-down" viewBox="0 0 16 16">
                                                        <path d="M12.5 9a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7m.354 5.854 1.5-1.5a.5.5 0 0 0-.708-.708l-.646.647V10.5a.5.5 0 0 0-1 0v2.793l-.646-.647a.5.5 0 0 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
                                                        />
                                                        <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z" />
                                                    </svg>
                                                </div>
                                                <div  className="content">
                                                    <h5  className="mb-0"> Consumer Staples</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div  className="col-lg col-6 col-sm-4">
                                            <div  className="style_1">
                                                <div  className="icon mb-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  className="bi bi-person-plus" viewBox="0 0 16 16">
                                                        <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                                                        <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5" />
                                                    </svg>
                                                </div>
                                                <div  className="content">
                                                    <h5  className="mb-0">Consumer Discretionary</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div  className="col-lg col-6 col-sm-4">
                                            <div  className="style_1">
                                                <div  className="icon mb-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  className="bi bi-tv" viewBox="0 0 16 16">
                                                        <path d="M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5M13.991 3l.024.001a1.5 1.5 0 0 1 .538.143.76.76 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.5 1.5 0 0 1-.143.538.76.76 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.5 1.5 0 0 1-.538-.143.76.76 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.5 1.5 0 0 1 .143-.538.76.76 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2"
                                                        />
                                                    </svg>
                                                </div>
                                                <div  className="content">
                                                    <h5  className="mb-0">Telecommunication</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div  className="col-lg col-6 col-sm-4">
                                            <div  className="style_1">
                                                <div  className="icon mb-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  className="bi bi-card-checklist" viewBox="0 0 16 16">
                                                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z" />
                                                        <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0M7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0"
                                                        />
                                                    </svg>
                                                </div>
                                                <div  className="content">
                                                    <h5  className="mb-0">Technology</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div  className="col-lg col-6 col-sm-4">
                                            <div  className="style_1">
                                                <div  className="icon mb-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  className="bi bi-hospital" viewBox="0 0 16 16">
                                                        <path d="M8.5 5.034v1.1l.953-.55.5.867L9 7l.953.55-.5.866-.953-.55v1.1h-1v-1.1l-.953.55-.5-.866L7 7l-.953-.55.5-.866.953.55v-1.1zM13.25 9a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25zM13 11.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25zm.25 1.75a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25zm-11-4a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 3 9.75v-.5A.25.25 0 0 0 2.75 9zm0 2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25zM2 13.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25z"
                                                        />
                                                        <path d="M5 1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 1 1v4h3a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h3V3a1 1 0 0 1 1-1zm2 14h2v-3H7zm3 0h1V3H5v12h1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1zm0-14H6v1h4zm2 7v7h3V8zm-8 7V8H1v7z" />
                                                    </svg>
                                                </div>
                                                <div  className="content">
                                                    <h5  className="mb-0">Health Care</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div  className="col-lg col-6 col-sm-4">
                                            <div  className="style_1 mb-0">
                                                <div  className="icon mb-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  className="bi bi-briefcase" viewBox="0 0 16 16">
                                                        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5m1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0M1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5"
                                                        />
                                                    </svg>
                                                </div>
                                                <div  className="content">
                                                    <h5  className="mb-0">Real Estate</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div  className="col-lg col-6 col-sm-4">
                                            <div  className="style_1 mb-0">
                                                <div  className="icon mb-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  className="bi bi-bank" viewBox="0 0 16 16">
                                                        <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.5.5 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z"
                                                        />
                                                    </svg>
                                                </div>
                                                <div  className="content">
                                                    <h5  className="mb-0">Financials</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div  className="col-lg col-6 col-sm-4">
                                            <div  className="style_1 mb-0">
                                                <div  className="icon mb-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  className="bi bi-bank" viewBox="0 0 16 16">
                                                        <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.5.5 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z"
                                                        />
                                                    </svg>
                                                </div>
                                                <div  className="content">
                                                    <h5  className="mb-0">Aerospance & Defense</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div  className="row">
                            <div  className="col-12">
                                <h1  className="text-black fw-semibold mb-3 h3">Explore more regulation</h1>
                                <div  className="companies_filter border bg-info bg-opacity-10 rounded p-4 pb-5">
                                    <div  className="row">
                                        <div  className="col-lg-4">
                                            <h6  className="fw-semibold text-black">ESG Theme</h6>
                                            <select  className="form-select border fw-semibold text-black" >
                                                <option value="regulation-dashboard.html">Climate Change</option>
                                                <option value="regulation-dashboard.html">Circular Economy</option>
                                                <option value="regulation-dashboard.html">Community Development</option>
                                                <option value="regulation-dashboard.html">Compliance</option>
                                                <option value="regulation-dashboard.html">Energy Management</option>
                                                <option value="regulation-dashboard.html">Environment</option>
                                                <option value="regulation-dashboard.html">Governance</option>
                                                <option value="regulation-dashboard.html">Health and Safety</option>
                                                <option value="regulation-dashboard.html">Human Rights</option>
                                                <option value="regulation-dashboard.html">Product Quality and Safety</option>
                                                <option value="regulation-dashboard.html">Risk Management</option>
                                                <option value="regulation-dashboard.html">Social</option>
                                                <option value="regulation-dashboard.html">Supply Chain Management</option>
                                                <option value="regulation-dashboard.html">Water Management</option>
                                                <option value="regulation-dashboard.html">Overall</option>
                                            </select>
                                        </div>
                                        <div  className="col-lg-4 mt-3 mt-md-0">
                                            <h6  className="fw-semibold text-black">Company</h6>
                                            <select  className="form-select border fw-semibold text-black" >
                                                <option value="regulation-dashboard.html">All</option>
                                                <option value="regulation-dashboard.html">Samsung Electronics</option>
                                                <option value="regulation-dashboard.html">Solvay SA</option>
                                                <option value="regulation-dashboard.html">Volkswagen AG</option>
                                                <option value="regulation-dashboard.html">Siemens AG</option>
                                            </select>
                                        </div>
                                        <div  className="col-lg-4 mt-3 mt-md-0">
                                            <h6  className="fw-semibold text-black">Region</h6>
                                            <select  className="form-select border fw-semibold text-black" >
                                                <option value="regulation-dashboard.html">Overall</option>
                                                <option value="regulation-dashboard.html">North America</option>
                                                <option value="regulation-dashboard.html">Europe</option>
                                                <option value="regulation-dashboard.html">Asia Pacific</option>
                                                <option value="regulation-dashboard.html">Middle East & Africa</option>
                                                <option value="regulation-dashboard.html">South America</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
        </Layout>
       </>   
          

  );
};

export default Regulation;
