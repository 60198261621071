import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "./store";

const initialState = {
  loader: false,
  pageTitle: 'NeoImpact',
  description: '',
  index: '',
  ogImage: '',
  serverError: false,
  successMessage: false,
  layoutStyle: "default",
  layoutHover: "default",
  isFormSubmit: false,
  isFormReset: false,
  filter: {},
  signinShow: false,
  signupShow: false,
  accessShow: false,
  navigateTo: '/',
  refresh: 0
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setIndex: (state, action) => {
      state.index = action.payload;
    },
    setOgImage: (state, action) => {
      state.ogImage = action.payload;
    },
    setServerError: (state, action) => {
      state.serverError = action.payload;
      state.successMessage = false;

    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
      state.serverError = false;
    },
    setLayoutStyle: (state, action) => {
      state.layoutStyle = action.payload;
    },
    setLayoutHover: (state, action) => {
      state.layoutHover = action.payload;
    },
    setIsFormReset: (state, action) => {
      state.isFormReset = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },

    setSigninShow: (state, action) => {
      state.signinShow = action.payload;
    },
    setSignupShow: (state, action) => {
      state.signupShow = action.payload;
    },
    setAccessShow: (state, action) => {
      state.accessShow = action.payload;
    },
    setNavigateTo: (state, action) => {
      state.navigateTo = action.payload;
    },
    setRefresh: (state) => {
      state.refresh += 1;
    },
  },
});

export default commonSlice.reducer;
export const { setLoader, setPageTitle, setServerError, setSuccessMessage, setLayoutStyle, setLayoutHover, setIsFormReset, setFilter, setSigninShow, setSignupShow, setAccessShow, setNavigateTo,setRefresh } = commonSlice.actions;

export function updateFilter(filter: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(setFilter(filter));
  };
}

// export function refresh(filter: any) {
//   return async (dispatch: AppDispatch) => {
//     dispatch(setRefresh());
//   };
// }

export function handleSignInModel(status: boolean) {
  return async (dispatch: AppDispatch) => {
    dispatch(setSigninShow(status));
    dispatch(setSignupShow(false));
  };
}

export function handleSignupModel(status: boolean) {
  return async (dispatch: AppDispatch) => {
    dispatch(setSignupShow(status));
    dispatch(setSigninShow(false));
  };
}

export function handleAccessShow(status: boolean) {
  return async (dispatch: AppDispatch) => {
    dispatch(setAccessShow(status));
    dispatch(setSigninShow(false));
    dispatch(setSignupShow(false));

  };
}
export function handleNavigation(to: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(setNavigateTo(to));
  };
}

export function signInRedirect(navigate=null, onModel = true) {
  return async (dispatch: AppDispatch) => {
    if (onModel) {
      dispatch(handleSignInModel(true))
    } else {
      navigate("/sign-in#");
    }
  };

}

export function signUpRedirect(navigate=null, onModel = true) {
  return async (dispatch: AppDispatch) => {
    if (onModel) {
      dispatch(handleSignupModel(true))
    } else {
      navigate("/sign-up");
    }
  };
}
