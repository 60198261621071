import { formatDate, formatNumber } from '../../utils/constant';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { getPatents } from '../../store/datasetSlice';
import Pagination from "react-js-pagination";
import { ShimmerTable, ShimmerThumbnail } from "react-shimmer-effects";
import { useParams } from 'react-router-dom';
import DataNotFound from '../DataNotFound';
// import { ShimmerTable,ShimmerThumbnail } from '../ShimmerUI';

function Patent() {

  const dispatch = useAppDispatch();
  const { companyId } = useParams();

  const [list, setList] = useState({ data: [], current_page: 1, per_page: 10, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const { patents } = useSelector(({ dataset }) => dataset);
  const { filter,refresh } = useSelector(({ common }) => common);
  const query = useMemo(() => ({
    keyword: filter?.keyword || '',
    companyId: filter?.company?._id || companyId || '',
    page: activePage,
    limit: 10
  }), [filter, companyId, activePage]);

  useMemo(() => {
    dispatch(getPatents(query));
  }, [refresh,activePage]);

  useEffect(() => {
    setActivePage(1)
  }, [,])

  useEffect(() => {
    setList(patents.data);
  }, [patents]);

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber)
  }

  return (
    <section className="mt-5">
      <div className="row">
        {
          patents?.loading ? Array(6).fill(null).map((_, index) => ( <div className="col-sm-2" key={index}> <ShimmerThumbnail height={100} width={150} /></div>)) :
            <>
              <div className="col-sm-2">
                <div className="rounded card ">
                  <div className="card-body">
                    <div className="card-title h5">Patent Records</div>
                    <p className="card-text">{formatNumber(patents?.data?.total || 0)}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="rounded card">
                  <div className="card-body">
                    <div className="card-title h5">Simple Families</div>
                    <p className="card-text">0</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="rounded card">
                  <div className="card-body">
                    <div className="card-title h5">Extended Families</div>
                    <p className="card-text">0</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="rounded card">
                  <div className="card-body">
                    <div className="card-title h5">Cites Patent</div>
                    <p className="card-text">0</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="rounded card">
                  <div className="card-body">
                    <div className="card-title h5">Cites By Patent</div>
                    <p className="card-text">0</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="rounded card">
                  <div className="card-body">
                    <div className="card-title h5">Patent Citation</div>
                    <p className="card-text">0</p>
                  </div>
                </div>
              </div>
            </>
        }
      </div>
      {/* <div className="row">
        {
          patents.loading ? Array(10).fill(<ShimmerThumbnail />) :
            list?.data?.map((data, index) => {
              return (
                <div className="col-12 " key={`patent-row-${index}`}>
                  <div className="companies_filter border  rounded p-4 mt-2 bg-white">
                    <div className={`div-table-results-row patent-row active`} >
                      <header>
                        <div className="listing-header pmr-listing-header clearfix">
                          <div className="listing-with-sidebar">

                            <ul className="list-inline header-meta">
                              <li>
                                <strong>Patent Numbers : </strong><span>{data?.patent_number}</span>
                              </li>
                              <li><strong>Patent Title : </strong> <span dangerouslySetInnerHTML={{ __html: data?.patent_title }}></span> </li>
                              <li><strong>Patent Assignees : </strong> <span dangerouslySetInnerHTML={{ __html: data?.assignees }}></span></li>
                              <li><strong>Patent Examiners : </strong> <span dangerouslySetInnerHTML={{ __html: data?.examiners }}></span></li>
                              <li><strong>Patent Inventors : </strong> <span dangerouslySetInnerHTML={{ __html: data?.inventors }}></span></li>
                              <li><strong>Patent CPCS : </strong> <span dangerouslySetInnerHTML={{ __html: data?.cpcs }}></span></li>
                              <li><strong>Patent Abstract : </strong> <span dangerouslySetInnerHTML={{ __html: shortenedTitle(data?.patent_abstract, 500) }}></span></li>
                              <li><strong>Patent Date : </strong><span dangerouslySetInnerHTML={{ __html: data?.patent_date }}></span></li>
                              <li><strong>Patent Kind : </strong><span dangerouslySetInnerHTML={{ __html: data?.patent_kind }}></span></li>
                              <li><strong>Patent Number Of Claims : </strong> <span dangerouslySetInnerHTML={{ __html: data?.patent_num_claims }}></span></li>
                              <li><strong>Patent Number Of Combined Citation : </strong> <span dangerouslySetInnerHTML={{ __html: data?.patent_num_combined_citations }}></span></li>
                              <li><strong>Patent Type : </strong> <span dangerouslySetInnerHTML={{ __html: data?.patent_type }}></span></li>
                              <li><strong>Updated On : </strong> <span dangerouslySetInnerHTML={{ __html: formatDate(data?.updatedAt) }}></span></li>

                            </ul>
                          </div>
                        </div>
                      </header>
                    </div>
                  </div>
                </div>
              )
            })
        }
        {(patents?.data?.data?.length <= 0 && !patents?.loading) && <DataNotFound title="No results" description="Unfortunately, no related patent could be found." />}

      </div> */}


      <div className="row">
        {
          patents?.loading ? <ShimmerTable row={10} col={7} /> :
            <div className="card card-border mb-4 table_layout2">
              <div className="position-relative">
                <div className="table-responsive mt-0">
                  <table className="table-bordered mb-0 table">
                    <thead className="">
                      <tr>
                        <th scope="col">Publication Identifier</th>
                        <th scope="col">Type	</th>
                        <th scope="col">Document Type	</th>
                        <th scope="col">Title</th>
                        <th scope="col">Classification-CPC</th>
                        <th scope="col">Publication Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        patents?.data?.data?.map((data: any, index: number) => {
                          return (
                            <tr>
                              <td style={{ width: "10%" }}><span dangerouslySetInnerHTML={{ __html: data?.patent_number }}></span></td>
                              <td style={{ width: "10%" }}><span dangerouslySetInnerHTML={{ __html: data?.patent_type }}></span></td>
                              <td style={{ width: "10%" }}><span dangerouslySetInnerHTML={{ __html: "Grant" }}></span></td>
                              <td style={{ width: "40%" }}><span dangerouslySetInnerHTML={{ __html: data?.patent_title }}></span></td>
                              <td style={{ width: "30%" }}><span dangerouslySetInnerHTML={{ __html: data?.cpcs }}></span></td>
                              <td style={{ width: "10%" }}><span dangerouslySetInnerHTML={{ __html: formatDate(data?.updatedAt) }}></span></td>

                            </tr>
                          )
                        })
                      }
                      {
                        (patents?.data?.length <= 0 && !patents?.loading) && <tr ><td colSpan={5}><DataNotFound title="No results" description="Unfortunately, no related patent could be found." /></td></tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        }
        {/* {(deals?.data?.data?.length <= 0 && !deals?.loading) && <DataNotFound title="No results" description="Unfortunately, no related deal could be found." />} */}

      </div>
      {
        list?.data?.length > 0 &&
        <div className='mt-4'>
          {/* {parseInt(patents?.data?.total)} */}
          <Pagination
            activePage={activePage}
            itemsCountPerPage={patents?.data?.per_page || 0}
            totalItemsCount={patents?.data?.total || 0}
            pageRangeDisplayed={5}
            itemClass="page-item"
            linkClass="page-link"
            onChange={handlePageChange}

          />
        </div>
      }
    </section>

  );
};

export default Patent;
