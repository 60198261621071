import { PayloadAction, createSlice } from "@reduxjs/toolkit";



const initialState = {
    regionDetail: {},
    regions: ['regions 1','regions 2','regions 3'],
};

const regionSlice = createSlice({
    name: "region",
    initialState,
    reducers: {
        setRegionDetails: (state, action : PayloadAction<any>) => {
            state.regionDetail = action.payload;
        },
        setRegions: (state, action : PayloadAction<any>) => {
            state.regions = action.payload;
        },
    },
});

export const { setRegionDetails, setRegions } = regionSlice.actions;
export default regionSlice.reducer;
