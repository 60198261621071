import React  from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsPower } from 'react-icons/bs';
import {  useSelector } from 'react-redux';
import { FeatherPersonCircleIcon, FeatherSettingIcon } from '../../../icons';
import { signOut } from '../../../store/authSlice';
import { useAppDispatch } from '../../../hooks/useAppDispatch';

export default function UserProfile() {
   const dispatch = useAppDispatch();
   const { user} = useSelector(({auth})=>auth)
   const navigate = useNavigate();

   async function handleLogout() {
      dispatch(signOut());
      navigate("/sign-in");
   }


   return <>
      <li className="nav-item">

         {!user ?
            <div className='dropdown'>
               <Link to={"/sign-in"}   role="button" className="btn btn-lg py-2 btn-outline-primary font-s-16 fw-medium rounded-4 ms-3 dropdown-toggle px-1 w-120p">{'Login'}</Link>
            </div>
            :
            <div className="dropdown">
               <Link  to={"#"} role="button" data-bs-display="static" data-bs-toggle="dropdown" data-dropdown-animation="" data-bs-auto-close="outside" aria-expanded="false" className="btn btn-lg py-2 btn-outline-primary font-s-16 fw-medium rounded-4 ms-3 dropdown-toggle px-1 w-120p nav_user_drop">Account</Link>
               <div className="dropdown-menu dropdown-menu-end">
                  <div className="p-2">
                     <div className="media">
                        <div className="media-head me-2">
                           <div className="avatar avatar-primary avatar-sm avatar-rounded">
                              <span className="initial-wrap">
                                 <FeatherPersonCircleIcon />
                              </span>
                           </div>
                        </div>
                        <div className="media-body">
                           <div className="dropdown d-flex link-dark">
                              Welcome,<br/>
                              {user.firstName } {  user.lastName}
                           </div>                           
                        </div>
                     </div>
                  </div>
                  <div className="dropdown-divider"></div>
                  <Link  to={"/profile?activeTab=manage-profile"} className="dropdown-item" title='Profile'>My Profile</Link>
                  <div className="dropdown-divider"></div>
                  <h6 className="dropdown-header">Manage Account</h6>
                 
                  <Link to={"/profile?activeTab=change-password"} className="dropdown-item"  title='Change Password'>
                     <span className="dropdown-icon feather-icon">
                        <FeatherSettingIcon />
                     </span>
                     <span>Change Password</span>
                  </Link>                 
                  <div className="dropdown-divider"></div>
                  <Link to="#" onClick={handleLogout} className="dropdown-item" role="button" title='Sign Out'>
                     <span className="dropdown-icon feather-icon">
                        <BsPower /></span>
                     <span>Sign Out</span>
                  </Link>
               </div>
            </div>}
      </li></>
}