import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetcherGet } from "../utils/fetcher";
import { setServerError } from "./commonSlice";
import { AppDispatch } from "./store";


const initialState = {
    company: { data: {}, loading: false },
    companies: { data: [], loading: false },
    companiesDropdown: { data: [], loading: false }
};

const companySlice = createSlice({
    name: "company",
    initialState,
    reducers: {
        setCompanyDetails: (state, action: PayloadAction<any>) => {
            state.company = action.payload;
        },
        setCompanies: (state, action: PayloadAction<any>) => {
            state.companies = action.payload;
        },
        setCompaniesDropdown: (state, action: PayloadAction<any>) => {
            state.companiesDropdown = action.payload;
        },

        setLoading: (state, action: PayloadAction<{ key: string; loading: boolean }>) => {
            state[action.payload.key].loading = action.payload.loading;
        }

    },
});

export const { setCompanyDetails, setCompanies, setCompaniesDropdown, setLoading } = companySlice.actions;
export default companySlice.reducer;

export function getCompanies({ q, page, limit }) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'companies', loading: true }));
        fetcherGet(`companies?q=${q}&page=${page}&limit=${limit}`)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setCompanies({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoading({ key: 'companies', loading: false }));
            });
    };
}
export function getCompaniesDropdown({ q, categoryId, limit }: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'companiesDropdown', loading: true }));
        fetcherGet(`company-dropdown?q=${q}&category_id=${categoryId}&limit=${limit}`)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setCompaniesDropdown({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoading({ key: 'companiesDropdown', loading: false }));
            });
    };
}

export function getCompanyDetail(id : string) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'company', loading: true }));
        fetcherGet(`company/${id}`)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setCompanyDetails({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoading({ key: 'company', loading: false }));
            });
    };
}

export function getCompaniesSearch({ q, categoryId, limit }: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'companies', loading: true }));
        fetcherGet(`company-dropdown?q=${q}&category_id=${categoryId}&limit=${limit}`)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setCompanies({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoading({ key: 'companiesDropdown', loading: false }));
            });
    };
}

