import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {   setServerError, setSuccessMessage, signInRedirect } from "../store/commonSlice";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { getUserDetails } from "../store/authSlice";


function AuthenticatedRoute({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Access token and user information from Redux store
  const { loader } = useSelector(({ common }) => common);
  const { user ,isVerified } = useSelector(({ auth }) => auth);

  useEffect(() => {
    let isLoggedIn = localStorage.getItem('is_loggedin');
      if (isLoggedIn == '1') {
        dispatch(getUserDetails());
      }
  }, [dispatch]);

  // Function to navigate to the login page
  const navigateToLogin = () => {
    localStorage.setItem('previousRoute', window.location.pathname + window.location.search);
    dispatch(signInRedirect(navigate,false)) 

    // navigate('/', { state: { from: location } });
  };

  // Handle redirection or loading state
  useEffect(() => {
    if (!loader) {
     
      let isLoggedIn = localStorage.getItem('is_loggedin');
      if (isLoggedIn !== '1') {
       return navigateToLogin();
      }
      if (user?._id && !user?.mobile_number) {
       // navigate(`/verify-account`)
      }
    }

  }, [loader, navigate, location, user, navigateToLogin]);

  useEffect(() => {
    dispatch(setSuccessMessage(""));
    dispatch(setServerError(""));
  }, [location, dispatch]);
   const isLoggedIn = localStorage.getItem('is_loggedin');
  // If loading is complete and user is authenticated, render the children components
  if (isLoggedIn == '1') {
    return <> {children}</>;
  }

  // If user is not authenticated, we will navigate to login page, so we can return null
  return null;
};

export default AuthenticatedRoute;
