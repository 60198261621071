
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { setPageTitle } from "../../store/commonSlice";
import { getUserDetails,  updateAccount } from "../../store/authSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { Spinner } from "react-bootstrap";
import CountryCodeInput from "../Inputs/CountryCodeInput";
import { toast } from "react-toastify";


export default function VerifyForm() {
    const dispatch = useAppDispatch();
    const { user, isLoggedIn } = useSelector(({ auth }) => auth);
    
    const { loader, serverError, successMessage ,navigateTo} = useSelector(({ common }) => common);
    const defaultValues = {
        id: '',
        mobile_number: '',
        description: '',
    };
    const [detail, setDetails] = useState(defaultValues);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(setPageTitle("NeoImpact - Sign Up"))

    }, [dispatch])
    useEffect(() => {
        if(user?._id ){
            setDetails((oldDetail) => { return { ...oldDetail, id: user?._id } })
        }
    }, [user])


    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setErrorMessage("");
        if (detail?.mobile_number === "") {
            setErrorMessage('What\'s your phone number?');
            return;
        }
        if(user?._id && user?.is_verified == 0){
             toast.error("You need to verify email first");
             return false
        }
      
        setErrorMessage("")
        dispatch(updateAccount(detail))
            .then((res) => {
                setDetails(defaultValues);
                localStorage.getItem('is_loggedin') == '1' &&  dispatch(getUserDetails())
            }).catch((err) => {
                console.log("error", err)
            });

    }



    // const handleOnChange = (event) => {
    //     let name = event.target.name;
    //     let value = event.target.value;
    //     let type = event.target.type;
    //     if (type === 'checkbox') {
    //         let value = event.target.checked;
    //         setDetails((oldDetail) => { return { ...oldDetail, [name]: value } })
    //     } else {
    //         setDetails((oldDetail) => { return { ...oldDetail, [name]: value } })
    //     }
    // }
    const onChangeNumber = (value :any) => {
        setDetails((oldDetail) => { return { ...oldDetail, mobile_number: value } })

    }
    const onChangeCountryCode = (value : any) => {
        setDetails((oldDetail) => { return { ...oldDetail, country_code: value } })

    }
useEffect(()=>{
    if(navigateTo != '/'){
        navigate(navigateTo)  
    }

},[navigateTo])

    return (
        <form >
            <div className="">
                <div className="text-center mb-4">
                    <h4 className="my-0">Welcome to NeoImpact</h4>
                </div>
                <div className="row gx-3">
                    {(errorMessage || serverError) && <p className='text-danger p-xs mb-2 text-center'>{errorMessage ? errorMessage : serverError}</p>}
                    {successMessage && <p className='text-success p-xs mb-2 text-center'>{successMessage}</p>}

                </div>
                <div className="row gx-3"></div>
                <div className="form-floating mb-4  ">
                    <input className="form-control" id="floatingEmail" placeholder="Business Email Address*" value={user?.email}
                        name="email"
                        // onChange={handleChange}
                        type="text"
                        disabled={true}
                    />
                    <label htmlFor="floatingEmail">Business Email Address</label>
                </div>
                <div className="form-floating mb-4 ">
                    <CountryCodeInput onChangeNumber={onChangeNumber} disabled={user?.mobile_number ? true : false}  defaultValue={user?.mobile_number} defaultCountryCode={'+91'} onChangeCountryCode={onChangeCountryCode} inputClassName="h-50p"></CountryCodeInput>
                    <label htmlFor="floatingPhone" className="disbalePhonelabel" ></label>
                </div>

                <div className="form-floating mb-4  ">
                    <textarea className="form-control" rows={6} placeholder="Please share your research requirements*" value={user?.description} name="description" disabled={user?.description ? true : false}>{user?.description}</textarea>
                    <label htmlFor="floatingRR">Please share your research requirements*</label>
                </div>
                <div className="mb-4" >
                </div>
                <button id="VABtn" disabled={false} onClick={handleSubmit} className="as_info_btn  btn-uppercase btn-block" title="Go to my account">{loader ? <Spinner /> : "Go to my account"}</button>
            </div>
        </form>
    )
}