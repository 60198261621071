import React, { Suspense, useEffect } from "react";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";
import SigninModel from "../../component/Models/SigninModel";
import SignupModel from "../../component/Models/SignupModel";
import AccessModel from "../../component/Models/AccessModel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getUserDetails } from "../../store/authSlice";

function LandingLayout({ children }) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { navigateTo } = useSelector(({ common }) => common);
   useEffect(()=>{
    dispatch(getUserDetails());
   },[dispatch])
    useEffect(()=>{
        if(navigateTo != '/'){
            navigate(navigateTo)
        }
    },[navigateTo])
    return (
        <>
            <Suspense>
                <LandingHeader />
                {children}
                <LandingFooter />
            </Suspense>
            <SigninModel />
            <SignupModel />
            <AccessModel />
        </>
    );
}

export default LandingLayout;
