import React from "react";
import { Link } from "react-router-dom";
import logo from '../../assets/img/logo.png';
function LandingFooter () {

    return (
        <footer className="">
            <div className="footer_2 py-lg-7 py-5">
                <div className="container position-relative z-index">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="">
                            <h5 className="text_purple fw-bold mt-2 mb-3 pb-1">Neo Impact ESG Tool</h5>
                                <h6 className="font-s-13 my-3 lh-base">Explore the unexplored and stay on top of your portfolio or organizational ESG performance by using NeoImpact’s 360° ESG solutions and attain alpha market edge</h6>
                                <ul className="follow_link pt-3">
                                    <li>
                                        <Link to={"#"} className=""><svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279.1 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.4 0 225.4 0c-73.22 0-121.1 44.38-121.1 124.7v70.62H22.89V288h81.39v224h100.2V288z"></path></svg></Link>
                                        <Link to={"#"} className=""><svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.4 151.7c.325 4.548 .325 9.097 .325 13.65 0 138.7-105.6 298.6-298.6 298.6-59.45 0-114.7-17.22-161.1-47.11 8.447 .974 16.57 1.299 25.34 1.299 49.06 0 94.21-16.57 130.3-44.83-46.13-.975-84.79-31.19-98.11-72.77 6.498 .974 12.99 1.624 19.82 1.624 9.421 0 18.84-1.3 27.61-3.573-48.08-9.747-84.14-51.98-84.14-102.1v-1.299c13.97 7.797 30.21 12.67 47.43 13.32-28.26-18.84-46.78-51.01-46.78-87.39 0-19.49 5.197-37.36 14.29-52.95 51.65 63.67 129.3 105.3 216.4 109.8-1.624-7.797-2.599-15.92-2.599-24.04 0-57.83 46.78-104.9 104.9-104.9 30.21 0 57.5 12.67 76.67 33.14 23.72-4.548 46.46-13.32 66.6-25.34-7.798 24.37-24.37 44.83-46.13 57.83 21.12-2.273 41.58-8.122 60.43-16.24-14.29 20.79-32.16 39.31-52.63 54.25z"></path></svg></Link>
                                        <Link to={"#"} className=""><svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.3 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.6 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.3 61.9 111.3 142.3V448z"></path></svg></Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mt-4 mt-sm-0">
                            <h5 className="text_purple fw-bold mt-2 mb-3 pb-1">Solutions</h5>
                            <div className="footer_2_link">
                                <ul>
                                    <li className="mb-3"><Link to={"#"}>ESG Intelligence Platform</Link></li>
                                    <li className="mb-3"><Link to={"#"}>Data Centralization</Link></li>
                                    <li className="mb-3"><Link to={"#"}>Impact Benchmarking</Link></li>
                                    <li className="mb-3"><Link to={"#"}>Regulatory Solutions</Link></li>
                                    <li><Link to={"#"}>Thematic Research</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mt-4 mt-sm-5 mt-lg-0">
                            <h5 className="text_purple fw-bold mt-2 mb-3 pb-1">Contact Info</h5>
                            <div className="footer_2_link">
                                <ul>
                                    <li className="mb-3">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                                </svg>
                                            </div>
                                            <div className="flex-grow-1 ms-2">
                                            NeoImpact A-Block, BHIVE Workspace – No.112, AKR Tech Park, <br />
                                            7th Mile Hosur Rd, Krishna Reddy Industrial Area, 
                                            <br />Bengaluru, KA, 560068 India
                                            </div>
                                        </div>
                                    </li>
                                    <li className="mb-3">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-inbound" viewBox="0 0 16 16">
                                                    <path d="M15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0zm-12.2 1.182a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                                </svg>
                                            </div>
                                            <div className="flex-grow-1 ms-2">
                                            +91-9503232902
                                            </div>
                                        </div>
                                    </li>
                                    <li className="mb-3">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                                </svg>
                                            </div>
                                            <div className="flex-grow-1 ms-2">
                                                query@neoimpact.com
                                            </div>
                                        </div>
                                    </li>
                                    <li className="">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-alarm" viewBox="0 0 16 16">
                                                    <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z" />
                                                    <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z" />
                                                </svg>
                                            </div>
                                            <div className="flex-grow-1 ms-2">
                                                Opening Hours: 9am to 5pm
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 mt-4 mt-sm-5 mt-lg-0">
                            <h5 className="text_purple fw-bold mt-2 mb-3 pb-1">Newsletter</h5>
                            <p className="font-s-13 my-3 lh-base"></p>
                            <div className="footer_2_newsletter">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_2_bottom bg-white">
                <div className="container">
                    <div className="d-flex">
                        <p className="my-3 text-center mx-auto mx-md-0">2024 NeoImpact. All Right Reserved</p>
                        <div className="ms-auto d-none d-md-block">
                            <Link to={"#"} className="my-3">Home</Link>
                            <Link to={"#"} className="my-3">About</Link>
                            <Link to={"#"} className="my-3">Resources</Link>
                            <Link to={"#"} className="my-3">FAQ</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default LandingFooter;