import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import DynamicMeta from '../../component/DynamicMeta';
import { useDispatch, useSelector } from 'react-redux';
import { setPageTitle } from '../../store/commonSlice';
import { Spinner } from 'react-bootstrap';
import Layout from '../../layouts/dashboard/Layout';
import ChangePassword from './ChangePassword';

function Profile() {
    const dispatch = useDispatch();
    const { user } = useSelector(({ auth }) => auth);
    const [searchParams, setSearchParams] = useSearchParams();
    const { loader, successMessage } = useSelector(({ common }) => common);

    const [userDetail, setUserDetail] = useState({
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
    });
    const activeTab = searchParams.get('activeTab');

    useEffect(() => {
        dispatch(setPageTitle("NeoImpact -  Manage Profile"))
        setUserDetail({ firstName: user?.firstName, lastName: user?.lastName, email: user?.email })
    }, [dispatch, user])
    function handleSubmit() {

    }

    const handleProfileChange = (event: any) => {
        const { name, value, type, checked } = event.target;
        setUserDetail((oldDetail) => ({
            ...oldDetail,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleTabClick = (tab: string) => {
        setSearchParams({ activeTab: tab });
    };
    return (
        <> <DynamicMeta />
            <Layout >
                <div className="hk-pg-wrapper pb-10">
                    <div className="container-fluid px-4">
                        <div className="hk-pg-body pt-2">
                            <div className="profile-wrap">
                                <div className="profile-intro mt-0">
                                    <div className="card card-flush mw-400p bg-transparent">
                                        <div className="row">
                                            <h4>{user?.firstName} {user?.lastName}
                                                <i className="bi-check-circle-fill fs-6 text-blue" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Top endorsed"></i>
                                            </h4>
                                            <p>
                                                {user ? user.email : ""}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <header className="profile-header w-25">
                                    <ul className="nav nav-line nav-tabs nav-icon nav-light h-100 d-flex justify-content-between">
                                        <li className="nav-item">
                                            <Link className={`d-flex align-items-center nav-link h-100 ${activeTab === 'manage-profile' ? 'active' : ''} `} data-bs-toggle="tab" to={'profile?activeTab=manage-profile'} onClick={() => handleTabClick("manage-profile")} title='Profile' >
                                                <span className="nav-link-text">Profile</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className={`d-flex align-items-center nav-link h-100 ${activeTab === 'change-password' ? 'active' : ''}`} data-bs-toggle="tab" to={'profile?activeTab=change-password'} onClick={() => handleTabClick("change-password")} title='Password'>
                                                <span className="nav-link-text">Password</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </header>
                                <div className="row mt-4">
                                    <div className={activeTab === 'contact-us' || activeTab === 'invite-colleagues' || activeTab === 'subscription' ? "col-lg-12" : "col-lg-12" + " mb-lg-0 mb-3"}>
                                        <div className="tab-content mt-0" id="myTabContent">
                                            <div className={`tab-pane fade ${(activeTab === null || activeTab === 'manage-profile') ? 'show active' : ''}`} id="profile_tab" role="tabpanel" aria-labelledby="profile_tab-tab">
                                                <div className="col-lg-12">
                                                    <div className="">
                                                        <div className="">

                                                            <div className="row">
                                                                <form onSubmit={handleSubmit}>
                                                                    {/* <div className="gx-3 row"><div className="col-sm-12"><div><div className="media align-items-center"><div className="media-head me-5"><div className="avatar avatar-rounded avatar-xxl"><img src="/assets/avatar3-CedfJ5CS.jpg" alt="user" className="avatar-img" /></div></div><div className="media-body"><button type="button" className="btn-file mb-1 btn btn-soft-primary" fdprocessedid="pzwq67">Upload Photo<input type="file" className="upload form-control" /></button><div className="form-text text-muted form-text">For better preview recommended size is 450px x 450px. Max size 5mb.</div></div></div></div></div></div> */}
                                                                    <div className="row gx-3">
                                                                        <div className='col-lg-4 col-sm-6'>

                                                                            <div className="title title-xs title-wth-divider text-primary text-uppercase my-4"><span>Personal Info</span></div>

                                                                            <div className='row'>
                                                                                {successMessage && <p className='text-success mb-3'>{successMessage}</p>}
                                                                                <div className="col-lg-12">
                                                                                    <div className="mb-4">
                                                                                        <label className="text_purple2 font-s-12 fw-semibold mb-1" >First Name</label>
                                                                                        <input
                                                                                            id="firstName"
                                                                                            type="text"
                                                                                            className="form-control bg-transparent"
                                                                                            name="firstName"
                                                                                            value={userDetail?.firstName}
                                                                                            required
                                                                                            onChange={handleProfileChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-12">
                                                                                    <div className="mb-4">
                                                                                        <label className="text_purple2 font-s-12 fw-semibold mb-1" >Last Name</label>
                                                                                        <input
                                                                                            id="lastName"
                                                                                            type="text"
                                                                                            className="form-control bg-transparent"
                                                                                            name="lastName"
                                                                                            value={userDetail?.lastName}
                                                                                            required
                                                                                            onChange={handleProfileChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-lg-12">
                                                                                    <div className="mb-4">
                                                                                        <label className="text_purple2 font-s-12 fw-semibold mb-1" >Email</label>
                                                                                        <input
                                                                                            id="email"
                                                                                            type="text"
                                                                                            className="form-control bg-transparent"
                                                                                            name="email"
                                                                                            value={userDetail?.email}
                                                                                            onChange={handleProfileChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="row gx-3">
                                                                        <div className="col-lg-12">
                                                                            <button disabled={loader} type="submit" className="btn btn-primary-horizon" title='Save Changes'>{loader ? <Spinner /> : "Save Changes"}</button>
                                                                        </div>
                                                                    </div>


                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                            {activeTab === 'change-password' &&
                                                <ChangePassword activeTab={activeTab}></ChangePassword>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Profile;