import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// TypeScript expects more specific types for elements
const rootElement = document.getElementById('root');

// Guard to ensure rootElement is not null
if (rootElement) {
  // Explicitly casting the result of getElementById to HTMLDivElement
  const root = ReactDOM.createRoot(rootElement as HTMLElement);
  
  root.render( <App />)
  reportWebVitals(console.log)
}

