import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { setServerError, setSuccessMessage } from '../store/commonSlice';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import SigninModel from '../component/Models/SigninModel';
import SignupModel from '../component/Models/SignupModel';
import AccessModel from '../component/Models/AccessModel';
import { getUserDetails } from '../store/authSlice';
// import { ToastContainer } from 'react-toastify';

const PrivateLoginRoute = (props:any) => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {isLoggedIn,user} = useSelector(({auth})=>auth)
    const { navigateTo } = useSelector(({ common }) => common);

    useEffect(() => {
        dispatch(setSuccessMessage(""))
        dispatch(setServerError(""))
    }, [dispatch,location]);

    useEffect(()=>{
        if(navigateTo != '/'){
            navigate(navigateTo)
        }
        localStorage.getItem('is_loggedin') == '1' 
        &&  dispatch(getUserDetails())

    },[navigateTo,dispatch])

     return (

        <React.Fragment>
            {/* <ToastContainer
            position="top-center"

            /> */}
            {
                 (localStorage.getItem('is_loggedin') === '1') ? <Navigate to={'/dashboard'} replace /> : props.children
            }
            <SigninModel />
            <SignupModel />
            <AccessModel />
        </React.Fragment>

    );
};

export default PrivateLoginRoute;
