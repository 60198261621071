
import React, { lazy, Suspense, useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import thumbnail_logo from "../../assets/img/thumbnailImage.png"
import { useSelector } from "react-redux";
import { getSectorsDetails } from "../../store/sectorsSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { ShimmerTitle } from 'react-shimmer-effects';
import DynamicMeta from "../../component/DynamicMeta";
import Layout from "../../layouts/dashboard/Layout";
import { Spinner } from "react-bootstrap";

const EsgDiscloser = lazy(() => import("../../component/EsgDiscloser"))
const PatentGraph = lazy(() => import("../../component/PatentGraph"))
const TopEsgStories = lazy(() => import("../../component/TopEsgStories"))
const NewsRow = lazy(() => import("../../component/Rows/News"))
const RegulationRow = lazy(() => import("../../component/Rows/RegulationRow"))
const JobRow = lazy(() => import("../../component/Rows/Jobs"))
const DealsRow = lazy(() => import("../../component/Rows/Deals"))
const PatentRow = lazy(() => import("../../component/Rows/Patent"))
const Monitors = lazy(() => import("../../component/Monitor"))
const FilterBy = lazy(() => import("../../component/FilterBy"))

function Sector() {
    const dispatch = useAppDispatch();
    const { sectorId } = useParams();
    const [page, setPage] = useState('');
    const defaultComponentsOrder = ['SectorFilter', 'SubSectorFilter', 'CompanyFilter'];
    const [componentsOrder, setComponentsOrder] = useState(defaultComponentsOrder);
    const [filterBy, setFilterBy] = useState('Sector');
    const { sectorDetail } = useSelector(({ sector }) => sector)

    useEffect(() => {
        switch (page) {
            case 'patent':
                setComponentsOrder(['KeywordFilter', 'CompanyFilter']);
                setFilterBy('Patent')
                break;
            case 'deals':
                setComponentsOrder(['CompanyFilter', 'SectorFilter', 'SubSectorFilter']);
                setFilterBy('Deals')
                break;
            case 'jobs':
                setComponentsOrder(['CompanyFilter', 'SectorFilter', 'SubSectorFilter', 'FromYearFilter', 'ToYearFilter']);
                setFilterBy('Jobs')
                break;
            case 'regulations':
                setComponentsOrder(['KeywordFilter', 'RegionFilter', 'CountryFilter']);
                setFilterBy('Regulations')
                break;
            case 'news':
                setComponentsOrder(['CompanyFilter', 'SectorFilter', 'SubSectorFilter', 'FromYearFilter', 'ToYearFilter']);
                setFilterBy('News')
                break;
            default:
                setComponentsOrder(defaultComponentsOrder);
                setFilterBy('Sector')
                break;
        }
    }, [page]);
    const handleTabChange = (page: string) => {
        setPage(page)
    }
  
    useEffect(() => {
        dispatch(getSectorsDetails(sectorId));
        setPage('');
    }, [dispatch, sectorId]);
    return (
        <>
            <DynamicMeta />
            <Layout >
                <Suspense fallback={<Spinner></Spinner>}>
                    <div className="hk-pg-wrapper pb-10">
                        <div className="container-fluid px-4">
                            <div className="hk-pg-body pt-2">
                                <FilterBy filterBy={filterBy} componentsOrder={componentsOrder} />
                                <Monitors handleTabChange={handleTabChange} />
                                <section>
                                    <div className="row mt-4 justify-content-center">
                                        <div className="col-lg-1">
                                            <img src={thumbnail_logo} className="img-fluid" width="100" height="42" alt="logo" />
                                        </div>
                                        <div className="col-lg-4">
                                            <h5 className="fw-semibold mb-0">{sectorDetail?.data?.name ? `${sectorDetail?.data?.name} , ESG Risk Rating Rating Overview` : <ShimmerTitle line={1} gap={10} variant="primary" />}</h5>
                                        </div>
                                        <div className="col-lg-7 text-end">
                                            <button className="companies_report_button_outline rounded-pill">Download Executive
                                                Summary</button><br />
                                            <button className="companies_report_button rounded-pill mt-3">Download Full Report</button>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <h5 className="text-black fw-semibold mb-3"> {page?.toUpperCase()}</h5>

                                        </div>
                                    </div>
                                </section>
                                <div className="row">
                                    {
                                        page === '' && <>
                                            <div className="col-lg-8 companies_sec">
                                                <EsgDiscloser />
                                                <PatentGraph />

                                            </div>
                                            <div className="col-lg-4">
                                                <TopEsgStories />
                                            </div>
                                        </>
                                    }

                                    <div className="col-12">
                                        {page === 'patent' && <PatentRow />}
                                        {page === 'deals' && <DealsRow />}
                                        {page === 'jobs' && <JobRow />}
                                        {page === 'news' && <NewsRow />}
                                        {page === 'regulations' && <RegulationRow />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Suspense>
            </Layout>
        </>
    )
}

export default Sector;
