import React, { useEffect } from "react";
import SigninForm from "../../component/Forms/SigninForm";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { setPageTitle } from "../../store/commonSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SignUpLayout from "./SignUpLayout";
import logo from "../../assets/img/logo.png";
import SigninModel from "../../component/Models/SigninModel";
import SignupModel from "../../component/Models/SignupModel";
import AccessModel from "../../component/Models/AccessModel";

const Signin = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // const { isLoggedIn } = useSelector(({ auth }) => auth);

    useEffect(() => {
        dispatch(setPageTitle("NeoImpact - Sign In"))
    }, [dispatch])

    // // Redirect to dashboard if logged in
    // useEffect(() => {
    //     let isLoggedIn = localStorage.getItem('is_loggedin');

    //     if (isLoggedIn) {
    //         navigate("/dashboard")
    //     }
    // }, [isLoggedIn, navigate]);

    return (
        <>
            <SignUpLayout>
                <SigninForm />
            </SignUpLayout>

        </>
    );
};

export default Signin;
