import React from "react";
import { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import Select from "react-dropdown-select";

const SearchableDropdown = ({
    options,
    label,
    selectedVal,
    handleChange,
    setValue,
    isloading
}) => {
    const [query, setQuery] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const inputRef = useRef(null);

    useEffect(() => {
        document.addEventListener("click", toggle);
        return () => document.removeEventListener("click", toggle);
    }, []);

    const selectOption = (option: any) => {
        setQuery("")
        setValue(option);
        setIsOpen((isOpen) => !isOpen);
    };

    function toggle(e: any) {
        setIsOpen(e && e.target === inputRef.current);
    }

    const getDisplayValue = () => {
        if (query) return query;
        if (selectedVal) return selectedVal?.name;

        return "";
    };

    const filter = (options: any) => {
        return options.filter(
            (option: any) => option?.name?.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
    };

    const timerRef = useRef(null); // To store the timer ID

    const handleInputChange = (value) => {
        setQuery(value);
        // setIsOpen(true)
        if (timerRef.current) {
            clearTimeout(timerRef.current); // Clear previous timer
        }

        timerRef.current = setTimeout(() => {
            value.length > 2 && handleChange(value); // Trigger the change after the timeout
            if (value === '') {
                selectOption(null); // Select null option if input is cleared
            }
        }, 300); // 500ms delay (you can adjust this delay as needed)
    };

    useEffect(() => {
        // Cleanup the timer on unmount
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    return (
        <div className="search-dropdown">
            <div className="control">
                <div className="selected-value">
                    <input
                        className="form-select border fw-semibold text-black"
                        ref={inputRef}
                        type="text"
                        value={getDisplayValue()}
                        name="searchTerm"
                        placeholder={`Select ${label}`}
                        onChange={(e) => handleInputChange(e.target.value)} // Update the input value and trigger debounced handleChange

                        onFocus={() => query.length >= 3 && handleChange(query)}
                        onClick={toggle}
                        autoComplete="off"
                    />
                </div>
                <div className={`arrow ${isOpen ? "open" : ""}`}></div>
            </div>

            <div className={`options ${isOpen ? "open" : ""}`}>
                {
                    !isloading && options.length > 0 ?
                        filter(options).map((option: any, index: number) => {
                            return (
                                <div
                                    onClick={() => selectOption(option)}
                                    className={`option ${option?.name === selectedVal ? "selected" : ""
                                        }`}
                                    key={`option-id-${index}`}
                                >
                                    {option?.name}
                                </div>
                            );
                        })
                        :
                        !isloading && options.length <= 0 ? <div className={`option text-center`} key={`option-id-1`} > Not Found </div> : <div className={`option text-center`} key={`option-id-1`} > Loading... <Spinner style={{ height: '15px', width: '15px', marginTop: "2px", marginRight: "2px" }} /> </div>

                }
            </div>
        </div>
    );
};


export default SearchableDropdown;

export const GlobalSearch = () => {

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const [query, setQuery] = useState(params.get('search'));
    useEffect(() => {
        setQuery(params.get('search'))
    }, [params.get('search')])

    return (
        <form action="/search">
            <div className="search-dropdown">
                <div className="control">
                    <div className="selected-value">
                        <input
                            aria-autocomplete="list" aria-labelledby="downshift-12-label"
                            autoComplete="off" id="downshift-12-input"
                            data-transaction-name="search input"
                            placeholder="Find ESG Disclosure, Regulations, Patents, Investments and many more..."
                            className="form-control border text-black font-s-14 sui-search-box__text-input header-s-input ml-5"
                            type="text"
                            value={query || ''}
                            name="search"
                            onChange={(e) => {
                                setQuery(e.target.value);
                            }}
                        />
                        <div className="icn_search " style={{ left: "15px", top: "15px" }}><svg xmlns="http://www.w3.org/2000/svg"
                            width="20" height="20" viewBox="0 0 16 16" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd"
                                d="M10.5714 9.54286H10.0229L9.81717 9.33714C10.5029 8.58286 10.9143 7.55429 10.9143 6.45714C10.9143 3.98857 8.92571 2 6.45714 2C3.98857 2 2 3.98857 2 6.45714C2 8.92571 3.98857 10.9143 6.45714 10.9143C7.55429 10.9143 8.58286 10.5029 9.33714 9.81714L9.54286 10.0228V10.5714L12.9714 14L14 12.9714L10.5714 9.54286ZM6.45714 9.54286C4.74286 9.54286 3.37143 8.17143 3.37143 6.45714C3.37143 4.74286 4.74286 3.37143 6.45714 3.37143C8.17143 3.37143 9.54286 4.74286 9.54286 6.45714C9.54286 8.17143 8.17143 9.54286 6.45714 9.54286Z"
                                fill="#351249"></path>
                        </svg></div>

                    </div>

                </div>

            </div>
        </form>
    );
};

interface Option {
    value: number;
    label: string;
}
interface DropdownProps {
    label: string;
    options: Option[];
    handleChange: (value: Option[]) => void;
    value: Option[];
}
export const DropdownComponent = ({
    label,
    options,
    inputChange,
    handleChange,

    value,
    isloading
}) => {

    const timerRef = useRef<number | undefined>(undefined); // Timer reference for debounce


    // Handle search input change with debouncing
    const handleInputChange = ({ value }: any) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current); // Clear the previous timer
        }

        // Debounce the API call by 300ms
        timerRef.current = window.setTimeout(() => {
            // if (value.length > 2) {
                inputChange(value); // Fetch options if input length > 2
            // }
        }, 300);
    };

    useEffect(() => {
        // Cleanup the timer on component unmount
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    return (
        <div>
            <Select
                searchBy={'name'}
                valueField={'name'}
                labelField={'name'}
                options={options} // Dynamically updated options
                values={value} // The selected value(s)
                onChange={(selected) => handleChange(selected)} // Handle selected value
                handleKeyDownFn={(e) => handleInputChange(e.event.target)} // Call the API on search input
                searchable={true} // Enable search
                multi={false} // Single select (change to true for multiple selections)
                placeholder={`Select ${label}`} // Placeholder text
                className="form-control border text-black font-s-14 rounded header-s-input ml-5"
                loading={isloading}
                // clearable={true}
                onDropdownOpen={()=>handleInputChange({value:value || ''})}
                // sortBy={"name"}
            />
        </div>
    );
};


