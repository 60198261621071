import { getYearsDropdown } from "../utils/constant"
import React, { useEffect } from "react";
import { DropdownComponent } from "./SearchableDropdown"
import { getCompaniesDropdown } from "../store/companySlice";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { getSectorDropdown, getSubSectorDropdown } from "../store/sectorsSlice";
import { useParams } from "react-router-dom";
import { setRefresh, updateFilter } from "../store/commonSlice";

function FilterBy({ filterBy = "", componentsOrder }) {
    const dispatch = useAppDispatch();

    // Removed redundant useEffect as it was not doing anything

    // Map to hold component references
    const componentsMap = {
        'CompanyFilter': <CompanyFilter />,
        'CompanySearch': <CompanyFilter type="input" />,
        'RegionFilter': <RegionFilter />,
        'CountryFilter': <CountryFilter />,
        'SectorFilter': <SectorFilter />,
        'SubSectorFilter': <SubSectorFilter />,
        'YearFilter': <YearFilter />,
        'KeywordFilter': <KeywordFilter />,
        'FromDateFilter': <FromDateFilter />,
        'ToDateFilter': <ToDateFilter />,
        'FromYearFilter': <FromYearFilter />,
        'ToYearFilter': <ToYearFilter />,
        'FromMonthYearFilter': <FromMonthYearFilter />,
        'ToMonthYearFilter': <ToMonthYearFilter />,

    };

    return (
        <section>
            <div className="row">
                <div className="col-12">
                    <div className="companies_filter border bg-info-subtle rounded p-4">
                        {
                            filterBy != '' && <div className="col-12">
                                <h5 className="text-black fw-semibold mb-3">Filter {filterBy} By</h5>
                            </div>
                        }

                        <div className="row">
                            {
                                componentsOrder?.filter((value, index, array) => array.indexOf(value) === index)?.map((componentKey) => componentsMap[componentKey])
                            }
                            <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0">
                                <button className="btn company_filter_button w-100" onClick={() => dispatch(setRefresh())}>ANALYZE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FilterBy;

function CompanyFilter({ type = "select" }) {
    const dispatch = useAppDispatch();
    const { companiesDropdown, company } = useSelector(({ company }) => company);
    const { filter } = useSelector(({ common }) => common);

    useEffect(() => {
        company?.data?._id && dispatch(updateFilter({ ...filter, company: { id: company?.data?._id, name: company?.data?.name } }))
    }, [company])

    const handleCompanyChange = (q: any) => {
        let query = { q: q, categoryId: '', limit: 10 }
        if (filter?.subsector?._id) {
            query.categoryId = filter?.subsector?._id
        }
        dispatch(getCompaniesDropdown(query));
    }

    return (
        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0" key={'companySearch'}>
            <h6 className="fw-semibold text-black">Company Name</h6>
            {
                type == 'input' && <input type="text"
                    className="form-control border fw-semibold text-black"
                    value={filter?.company_name}
                    onChange={(e) => dispatch(updateFilter({ ...filter, company_name: e.target.value }))}
                    placeholder="Company Search"
                />

            }

            {
                type == 'select' &&
                <DropdownComponent
                    label="Company"
                    options={companiesDropdown?.data}
                    value={filter?.company}
                    handleChange={(val: any) => {
                        dispatch(updateFilter({ ...filter, company: val[0] }))
                    }}
                    inputChange={(val: any) => { handleCompanyChange(val) }}
                    isloading={companiesDropdown?.loading}
                />
            }

        </div>
    )
}

function RegionFilter() {
    return (
        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0" key={'regionFilter'}>
            <h6 className="fw-semibold text-black">Region</h6>
            <select className="form-select border fw-semibold text-black">
                <option value="0">Asia-Pacific</option>
                <option value="1">Company B</option>
                <option value="2">Company C</option>
            </select>
        </div>
    )
}

function SectorFilter() {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { sectorsDropdown, sectors } = useSelector(({ sector }) => sector);
    const { filter } = useSelector(({ common }) => common);

    const handleSectorChange = (q: any) => {
        dispatch(getSectorDropdown({ q: q, companyId: filter?.company?._id || '', limit: 10 }));
    }

    useEffect(() => {
        if (id && sectors?.data) {
            const selectedSector = sectors?.data?.find((val) => val?._id === id);
            if (selectedSector && filter.sector !== selectedSector) {
                dispatch(updateFilter({ ...filter, sector: selectedSector }));
            }
        }
    }, [id, sectors, filter, dispatch]);

    return (
        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0" key={'sectorFilter'}>
            <h6 className="fw-semibold text-black">Sector</h6>

            <DropdownComponent
                label="Sectors"
                options={sectorsDropdown?.data}
                value={filter?.sector}
                handleChange={(val: any) => {
                    dispatch(updateFilter({ ...filter, sector: val[0] }))
                }}
                inputChange={(val: any) => { handleSectorChange(val) }}
                isloading={sectorsDropdown?.loading}
            />
        </div>
    )
}

function SubSectorFilter() {
    const dispatch = useAppDispatch();
    const { subSectorsDropdown } = useSelector(({ sector }) => sector);
    const { filter } = useSelector(({ common }) => common);

    const handleSubSectorChange = (q: string) => {
        let query = { q: q, parentId: '', companyId: '', limit: 10 }
        if (filter?.sector?._id) {
            query.parentId = filter?.sector?._id
        }
        if (filter?.company?._id) {
            query.companyId = filter?.company?._id
        }
        dispatch(getSubSectorDropdown(query));
    }

    return (
        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0" key={'subSectorFilter'}>
            <h6 className="fw-semibold text-black">Sub Sector</h6>

            <DropdownComponent
                label="Sub Sectors"
                options={subSectorsDropdown?.data}
                value={filter?.subsector}
                handleChange={(val: any) => {
                    dispatch(updateFilter({ ...filter, subsector: val[0] }))
                }}
                inputChange={(val: any) => { handleSubSectorChange(val) }}
                isloading={subSectorsDropdown?.loading}
            />
        </div>
    )
}

function CountryFilter() {
    return (
        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0" key={'countryFilter'}>
            <h6 className="fw-semibold text-black">Country</h6>
            <select className="form-select border fw-semibold text-black">
                <option value="0">India</option>
                <option value="1">Company B</option>
                <option value="2">Company C</option>
            </select>
        </div>
    )
}

function YearFilter() {
    const dispatch = useAppDispatch();
    const { filter } = useSelector(({ common }) => common);

    return (
        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0" key={'yearFilter'}>
            <h6 className="fw-semibold text-black">Year</h6>

            <DropdownComponent
                label="Year"
                options={getYearsDropdown().map((year) => { return { _id: year.toString(), name: year.toString() } })}
                value={filter?.year}
                handleChange={(val: any) => {
                    dispatch(updateFilter({ ...filter, year: val[0] }))
                }}
                inputChange={(val: any) => { }}
                isloading={false}
            />
        </div>
    )
}



function KeywordFilter() {
    const dispatch = useAppDispatch();
    const { filter } = useSelector(({ common }) => common);
    return (
        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0" key={'regionFilter'}>
            <h6 className="fw-semibold text-black">Keyword</h6>
            <input type="text"
                className="form-control border fw-semibold text-black"
                value={filter?.keyword}
                onChange={(e) => dispatch(updateFilter({ ...filter, keyword: e.target.value }))}
            />
        </div>
    )
}

function FromDateFilter() {
    const dispatch = useAppDispatch();
    const { filter } = useSelector(({ common }) => common);
    return (
        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0" key={'regionFilter'}>
            <h6 className="fw-semibold text-black">From Date</h6>
            <input type="date"
                className="form-control border fw-semibold text-black"
                value={filter?.from_date || ''}
                onChange={(e) => dispatch(updateFilter({ ...filter, from_date: e.target.value }))}
            />
        </div>
    )
}

function ToDateFilter() {
    const dispatch = useAppDispatch();
    const { filter } = useSelector(({ common }) => common);
    return (
        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0" key={'ToDateFilter'}>
            <h6 className="fw-semibold text-black">To Date</h6>
            <input type="date"
                className="form-control border fw-semibold text-black"
                value={filter?.to_date || ''}
                onChange={(e) => dispatch(updateFilter({ ...filter, to_date: e.target.value }))}
            />
        </div>
    )
}

function FromYearFilter() {
    const dispatch = useAppDispatch();
    const { filter } = useSelector(({ common }) => common);
    return (
        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0" key={'yearFilter'}>
            <h6 className="fw-semibold text-black">From Year</h6>

            <DropdownComponent
                label="From Year"
                options={getYearsDropdown().map((year) => { return { _id: year.toString(), name: year.toString() } })}
                value={filter?.from_year}
                handleChange={(val: any) => {
                    dispatch(updateFilter({ ...filter, from_year: val[0] }))
                }}
                inputChange={(val: any) => { }}
                isloading={false}
            />
        </div>
    )
}

function ToYearFilter() {
    const dispatch = useAppDispatch();
    const { filter } = useSelector(({ common }) => common);
    return (
        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0" key={'yearFilter'}>
            <h6 className="fw-semibold text-black">To Year</h6>

            <DropdownComponent
                label="To Year"
                options={getYearsDropdown().map((year) => { return { _id: year.toString(), name: year.toString() } })}
                value={filter?.to_year}
                handleChange={(val: any) => {
                    dispatch(updateFilter({ ...filter, to_year: val[0] }))
                }}
                inputChange={(val: any) => { }}
                isloading={false}
            />
        </div>
    )
}

function FromMonthYearFilter() {
    const dispatch = useAppDispatch();
    const { filter } = useSelector(({ common }) => common);
    return (
        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0" key={'regionFilter'}>
            <h6 className="fw-semibold text-black">From Month</h6>
            <input type="month"
                className="form-control border fw-semibold text-black"
                value={filter?.from_month_year}
                onChange={(e) => dispatch(updateFilter({ ...filter, from_month_year: e.target.value }))}
            />
        </div>
    )
}

function ToMonthYearFilter() {
    const dispatch = useAppDispatch();
    const { filter } = useSelector(({ common }) => common);
    return (
        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0" key={'regionFilter'}>
            <h6 className="fw-semibold text-black">To Month</h6>
            <input type="month"
                className="form-control border fw-semibold text-black"
                value={filter?.to_month_year || ''}
                onChange={(e) => dispatch(updateFilter({ ...filter, to_month_year: e.target.value }))}
            />
        </div>
    )
}
