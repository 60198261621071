import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { setPageTitle } from "../../store/commonSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import ForgotPasswordForm from "../../component/Forms/ForgotPasswordForm";
import SignUpLayout from "./SignUpLayout";
import logo from "../../assets/img/logo.png";


function ForgotPassword() {
    const dispatch = useAppDispatch();
    const { loader, serverError, successMessage } = useSelector((state: any) => state.common); // Replace 'any' with the correct type

    useEffect(() => {
        dispatch(setPageTitle("NeoImpact - Forgot Password"));
    }, [dispatch]);

    return (
        <>

            <SignUpLayout>
                <ForgotPasswordForm />
            </SignUpLayout>
        </>
    );
};

export default ForgotPassword;
