
import Cookies from 'js-cookie';
import apiClient from "./app-client"


export default async function fetcher(
  request: any,
  method = "GET",
  data: any,
  baseUrl = process.env.REACT_APP_API_URL,
) {
  const options = {
    url: `${baseUrl}/${request}`,
    method,
    data,

  };
  await csrfGeneratee();
  // await setLoginDetails();
  return new Promise((resolve, reject) => {
    apiClient(options)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        if (!error.data) {
          error.data = { message: error.message };
        }
        reject(error);
      });
  })
}


export async function fetcherPost(
  request: string,
  data: any,
  method = "Post",
  baseUrl = process.env.REACT_APP_API_URL,
) {
  const options = {
    url: `${baseUrl}/api/${request}`,
    method,
    data
  };
  await csrfGeneratee();

  return new Promise((resolve, reject) => {
    apiClient(options)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        if (!error.data) {
          error.data = { message: error.message };
        }
        reject(error);
      });
  })

}


export async function fetcherGet(
  request: any,
  method = "GET",
  baseUrl = process.env.REACT_APP_API_URL,
) {
  const options = {
    url: `${baseUrl}/api/${request}`,
    method,

  };
  // await setLoginDetails();
  return new Promise((resolve, reject) => {
    apiClient(options)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        if (!error.data) {
          error.data = { message: error.message };
        }
        reject(error);
      });
  })

}
export async function fetcherPut(
  request: string,
  data: any,
  method = "Put",
  baseUrl = process.env.REACT_APP_API_URL,
) {
  const options = {
    url: `${baseUrl}/api/${request}`,
    method,
    data
  };
  await csrfGeneratee();
  return new Promise((resolve, reject) => {
    apiClient(options)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        if (!error.data) {
          error.data = { message: error.message };
        }
        reject(error);
      });
  })

}


export async function csrfGeneratee() {
  if (Cookies.get('XSRF-TOKEN') === undefined) {
    return new Promise((resolve, reject) => {
      apiClient.get('/sanctum/csrf-cookie').then(response => {
        // console.log("CSRF Genarated successfully")
        resolve(response)
      }).catch((error) => {
        // console.error("CSRF not generated - ", error)
        reject({ error: error })
      })
    });
  }
}
// export async function setLoginDetails () {  
//   if(localStorage.getItem('is_loggedin') !== "1"){
//    return new Promise((resolve, reject) => {
//      apiClient.get('users/get-current-user').then(response => {
//         localStorage.setItem('is_loggedin',"1");
//          resolve(response)
//      }).catch((error)=>{
//       localStorage.setItem('is_loggedin',"0");
//       reject({error:error})
//      })
//    });
//   }  
// }

// export async function setLoginDetails(
//   baseUrl = process.env.REACT_APP_API_URL,
// ) {
//   const options = {
//     url: `${baseUrl}/api/users/get-current-user`,
//     method: "GET"
//   };
//   await csrfGeneratee();
//   if (localStorage.getItem('is_loggedin') !== "1") {
//     return new Promise((resolve, reject) => {
//       apiClient(options)
//         .then((res) => {
//           localStorage.setItem("is_loggedin", "1");
//           resolve(res.data);
//         })
//         .catch((error) => {
//           if (!error.data) {
//             error.data = { message: error.message };
//           }
//           localStorage.setItem("is_loggedin", "0");
//           // reject(error);
//         });
//     })
//   }
// }

