import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Signin from '../pages/Auth/Signin';
import Signup from '../pages/Auth/Signup';
import Dashboard from '../pages/Dashboard/Dashboard';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import NotFound from '../pages/NotFound';
import AuthenticatedRoute from './AuthenticatedRoute';
import UpdatePassword from '../pages/Auth/UpdatePassword';
import PrivateLoginRoute from './PrivateLoginRoute';
import Profile from '../pages/profile';
import Region from '../pages/Region/Region';
import Sector from '../pages/Sector/Sector';
import Dataset from '../pages/Datasets/Dataset';
import ThematicReport from '../pages/ThematicReport/ThematicReport';
import Access from '../pages/Auth/Access';
import VerifyAccount from '../pages/Auth/VerifyAccount';
import CompanyList from '../pages/Company/CompanyList';
import CompanyDetail from '../pages/Company/CompanyDetail';
import CompanyLocations from '../pages/Company/CompanyLocations';
import RegulationDashboard from '../component/Rows/RegulationDashboard';
import RegulationList from '../component/Rows/RegulationList';
import Watchlist from '../pages/Watchlist/Watchlist';
import Recentvisit from '../pages/Watchlist/Recentvisit';
import Portfolio from '../pages/Watchlist/portfolio';
import CompanyTableView from '../pages/Company/CompanyTableView';

function AppRoutes() {
    return (
        <Routes>
            <Route  path='/' element={<Home />} />
            <Route  path='/sign-up' element={<PrivateLoginRoute><Signup /></PrivateLoginRoute>} />
            <Route  path='/access-form' element={<Access />} />
            <Route  path='/verify-account/:token?' element={<VerifyAccount />} />
            <Route  path='/sign-in' element={<PrivateLoginRoute><Signin /></PrivateLoginRoute>} />
             <Route  path='/forgot-password' element={<PrivateLoginRoute><ForgotPassword /></PrivateLoginRoute>} />
            <Route  path='/update-password/:token/:email' element={<PrivateLoginRoute><UpdatePassword /></PrivateLoginRoute>} />
            <Route  path='/dashboard' element={<AuthenticatedRoute><Dashboard /></AuthenticatedRoute>} />
            <Route  path='/profile' element={<AuthenticatedRoute><Profile /></AuthenticatedRoute>} />
            <Route  path='/company-detail/:companyId' element={<AuthenticatedRoute><CompanyDetail /></AuthenticatedRoute>} />
            <Route  path='/region/:regionId' element={<AuthenticatedRoute><Region /></AuthenticatedRoute>} />
            <Route  path='/sector/:sectorId' element={<AuthenticatedRoute><Sector /></AuthenticatedRoute>} />
            <Route  path='/dataset/:page?' element={<AuthenticatedRoute><Dataset /></AuthenticatedRoute>} />
            <Route  path='/thamatic-report/:id' element={<AuthenticatedRoute><ThematicReport /></AuthenticatedRoute>} /> 
            <Route  path='/search' element={<AuthenticatedRoute><CompanyList /></AuthenticatedRoute>} />
            <Route  path='/companies' element={<AuthenticatedRoute><CompanyTableView /></AuthenticatedRoute>} />
            <Route  path='/regulation-dashboard' element={<AuthenticatedRoute><RegulationDashboard /></AuthenticatedRoute>} />
            <Route  path='/regulation-list' element={<AuthenticatedRoute>< RegulationList /></AuthenticatedRoute>} />
            <Route  path='/watchlist' element={<AuthenticatedRoute><Watchlist /></AuthenticatedRoute>} />
            <Route  path='/recentvisit' element={<AuthenticatedRoute><Recentvisit /></AuthenticatedRoute>} />
            <Route  path='/portfolio' element={<AuthenticatedRoute><Portfolio /></AuthenticatedRoute>} />
            <Route  path='/company/locations' element={<AuthenticatedRoute><CompanyLocations /></AuthenticatedRoute>} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    )
}

export default AppRoutes;