import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { signOut } from "../../store/authSlice";
import { CompanyIcon, DatabooksIcon, HomeIcon, SectorIcon, SettingIcon, SidebarToggleIcon, SignOutIcon } from "../../icons";
import { setLayoutStyle } from "../../store/commonSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import logo from '../../assets/img/logo.png';
import { getSectors } from "../../store/sectorsSlice";

function Sidebar() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { layoutStyle } = useSelector(({ common }) => common);
    const { sectors } = useSelector(({ sector }) => sector);
    const [toggleCss, setToggleCss] = useState<string>("transparent");
    const [path, setPath] = useState<string>(location.pathname.split("/")[1]);
    // const [companylimit, setCompanyLimit] = useState<number>(5);
    const [sectorlimit, setSectorLimit] = useState<number>(5);

    const handleLogout = () => {
        dispatch(signOut());
        navigate("/sign-in");
    };

    const toggleLayoutStyle = () => {
        if (layoutStyle === 'default') {
            dispatch(setLayoutStyle('collapsed'));
            setToggleCss("#5BCAF4");
        } else {
            dispatch(setLayoutStyle('default'));
            setToggleCss("transparent");
        }
    };

    useEffect(() => {
        if (layoutStyle === 'default') {
            setToggleCss("transparent");
        } else {
            setToggleCss("#5BCAF4");
        }
    }, [layoutStyle]);

    useEffect(() => {
        setPath(location.pathname.split("/")[1]);
    }, [location]);

    useEffect(() => {
        // dispatch(getCompanies({ "limit": 10 }));
        dispatch(getSectors({ "q": '', "limit": 10 }));
    }, [dispatch]);
    return (
        <div>
            <div className="hk-menu hz_menu_2 borer-0">
                <div className="menu-header">
                    <span>
                        <Link to={"/"} className="navbar-brand">
                            <img className="brand-img img-fluid" src={logo} alt="logo" width="144" />
                        </Link>
                        <button onClick={toggleLayoutStyle}
                            className="btn  btn-sm btn-rounded btn-flush-dark flush-soft-hover navbar-toggle shadow " style={{ background: toggleCss, height: "31px", width: "31px" }}>
                            <span className="icon">
                                <span className="svg-icon fs-6">
                                    <SidebarToggleIcon />
                                </span>
                            </span>
                        </button>
                    </span>
                </div>

                <div data-simplebar="init" className="nicescroll-bar">
                    <div className="simplebar-wrapper" style={{ margin: "0px" }}>
                        <div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer"></div>
                        </div>
                        <div className="simplebar-mask">
                            <div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }}>
                                <div className="simplebar-content-wrapper" style={{ height: "auto", overflow: "hidden" }}>
                                    <div className="simplebar-content" style={{ padding: "0px" }}>
                                        <div className="menu-content-wrap">
                                            <div className="menu-group">
                                                <ul className="navbar-nav flex-column">
                                                    <li className="nav-item">
                                                        <Link to={"/dashboard"} className={`menu-nav-link nav-link ${path === "/" ? "active" : ''} `}
                                                            title="My NeoImpact">
                                                            <span className="nav-icon-wrap">
                                                                <HomeIcon />
                                                            </span>
                                                            <span className="nav-link-text"> Home </span>
                                                        </Link>
                                                    </li>
                                                    
                                                    <li className="nav-item">
                                                        <a href={"/portfolio"} className="menu-nav-link nav-link" title="Home">
                                                            <span className="nav-icon-wrap">
                                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"></path></svg>
                                                            </span>
                                                            <span className="nav-link-text"> My Portfolio </span>
                                                        </a>
                                                    </li>
                                                    {/* Companies */}
                                                    {/* <li className="nav-item">
                                                        <Link to={"#"} className={`menu-nav-link nav-link ${path === "company" ? "" : 'collapsed'} `}
                                                            data-bs-toggle="collapse" data-bs-target="#companies"
                                                            aria-expanded="false" title="Companies">
                                                            <span className="nav-icon-wrap">
                                                                <CompanyIcon />
                                                            </span>
                                                            <span className="nav-link-text">Companies</span>
                                                        </Link>
                                                        <ul id="companies"
                                                            className={`nav flex-column nav-children pt-0 ${path === "company" ? "show " : 'collapse'}`}>
                                                            <li className="nav-item">
                                                                <ul className="nav">
                                                                    {
                                                                        companies?.data?.map((company, index) => {
                                                                            return (<li className="nav-item d-flex w-100" key={`companies-${index}`}>
                                                                                <Link className={`menu-nav-link ${location.pathname === `/company/${company._id}` ? "active" : ''}`} role="button" title={company?.name} to={`/company/${company._id}`}> {company?.name} </Link>
                                                                            </li>)
                                                                        })
                                                                    }
                                                                    {
                                                                        companylimit < 10 ? <li key={`companies-${companies.length + 1}`} className="nav-item">
                                                                            <Link to={'#'} onClick={() => setCompanyLimit(10)} className="menu-nav-link more_link" title="View More">Show More</Link>
                                                                        </li> :
                                                                            <li key={`companies-${companies.length + 1}`} className="nav-item">
                                                                                <Link to={'#'} onClick={() => setCompanyLimit(5)} className="menu-nav-link more_link" title="View More">Show Less</Link>
                                                                            </li>
                                                                    }

                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li> */}
                                                    <li className="nav-item">
                                                        <Link to={"/companies"} className={`menu-nav-link nav-link ${path === "/companies" ? "active" : ''} `}
                                                            title="My NeoImpact">
                                                            <span className="nav-icon-wrap">
                                                                <CompanyIcon />
                                                            </span>
                                                            <span className="nav-link-text"> Companies </span>
                                                        </Link>
                                                    </li>
                                                    {/*  Sectors */}

                                                    <li className="nav-item">
                                                        <a className={`menu-nav-link nav-link ${path === "sector" ? "" : 'show'} `}
                                                            data-bs-toggle="collapse" data-bs-target="#sectors"
                                                            aria-expanded="false" href="/my-access" title="Sectors">
                                                            <span className="nav-icon-wrap">
                                                                <SectorIcon />
                                                            </span>
                                                            <span className="nav-link-text">Sectors</span>
                                                        </a>
                                                        <ul id="sectors"
                                                            className={`nav flex-column nav-children pt-0 ${path === "sector" ? "show " : 'show'}`}>
                                                            <li className="nav-item">
                                                                <ul className="nav">
                                                                    {
                                                                        sectors?.data?.map((sector: any, index: number) => {
                                                                            if (index <= sectorlimit) {
                                                                                return (<li className="nav-item d-flex w-100" key={'sector-' + index}>
                                                                                    <Link className={`menu-nav-link ${location.pathname === `/sector/${sector?._id}` ? "active" : ''}`} role="button" title={sector?.name} to={`/sector/${sector?._id}`}> {sector?.name} </Link>
                                                                                </li>)
                                                                            }
                                                                            return
                                                                        })
                                                                    }
                                                                    {
                                                                        sectorlimit < 10 ? <li key={`sector-${sectors?.data?.length + 1}`} className="nav-item">
                                                                            <Link to={'#'} onClick={() => setSectorLimit(10)} className="menu-nav-link more_link" title="View More">Show More</Link>
                                                                        </li> :
                                                                            <li key={`companies-${sectors?.data?.length + 1}`} className="nav-item">
                                                                                <Link to={'#'} onClick={() => setSectorLimit(5)} className="menu-nav-link more_link" title="View More">Show Less</Link>
                                                                            </li>
                                                                    }
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    {/* Regions */}

                                                    {/* <li className="nav-item">
                                                        <a className="menu-nav-link  nav-link collapsed"
                                                            data-bs-toggle="collapse" data-bs-target="#regions"
                                                            aria-expanded="false" href="/my-access" title="Regions">
                                                            <span className="nav-icon-wrap">
                                                                <SettingIcon />
                                                            </span>
                                                            <span className="nav-link-text">Regions</span>
                                                        </a>
                                                        <ul id="regions"
                                                            className="nav flex-column nav-children pt-0 collapse">
                                                            <li className="nav-item">
                                                                <ul className="nav">
                                                                    
                                                                    <li className="nav-item d-flex w-100">
                                                                        <Link className="menu-nav-link " role="button" title={''} to={`/region/1`}> Asia-Pacific </Link>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li> */}

                                                    {/* Dataset */}

                                                    <li className="nav-item">
                                                        <a className={`menu-nav-link nav-link ${path === "dataset" ? " show " : 'show'} `}
                                                            data-bs-toggle="collapse" data-bs-target="#dataset"
                                                            aria-expanded="false"  title="Dataset" onClick={()=>navigate("/dataset")}>
                                                            <span className="nav-icon-wrap">
                                                                <DatabooksIcon />
                                                            </span>
                                                            <span className="nav-link-text">Dataset</span>
                                                        </a>
                                                        <ul id="dataset"
                                                            className={`nav flex-column nav-children pt-0 ${path === "dataset" ? "show " : 'show'}`}>
                                                            <li className="nav-item">
                                                                <ul className="nav">
                                                                    <li className="nav-item d-flex w-100">
                                                                        <Link className={`menu-nav-link ${location.pathname === '/dataset/patent' ? "active" : ''}`} role="button" title="Patent" to={`/dataset/patent`}> Patents </Link>
                                                                    </li>

                                                                    <li className="nav-item d-flex w-100">
                                                                        <Link className={`menu-nav-link ${location.pathname === '/dataset/jobs' ? "active" : ''}`} role="button" title="Jobs" to={`/dataset/jobs`}> Jobs </Link>
                                                                    </li>
                                                                    {/* <li className="nav-item d-flex w-100">
                                                                        <Link className={`menu-nav-link ${location.pathname === '/dataset/deals' ? "active" : ''}`} role="button" title="Deals" to={`/dataset/deals`}> Deals </Link>
                                                                    </li>
                                                                    <li className="nav-item d-flex w-100">
                                                                        <Link className={`menu-nav-link ${location.pathname === '/dataset/news' ? "active" : ''}`} role="button" title="Regulations" to={`/dataset/news`}> News </Link>
                                                                    </li> */}
                                                                    <li className="nav-item d-flex w-100">
                                                                        <Link className={`menu-nav-link ${location.pathname === '/dataset/regulations' ? "active" : ''}`} role="button" title="Regulations" to={`/dataset/regulations`}> Regulations </Link>
                                                                    </li>

                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li className="nav-item">
                                                        <a className={`menu-nav-link nav-link collapsed`}
                                                            data-bs-toggle="collapse" data-bs-target="#account"
                                                            aria-expanded="false" href="/my-access" title="Settings">
                                                            <span className="nav-icon-wrap">
                                                                <SettingIcon />
                                                            </span>
                                                            <span className="nav-link-text">Settings</span>
                                                        </a>
                                                        <ul id="account"
                                                            className={`nav flex-column nav-children pt-0 ${path === "profile" ? "show " : 'collapse'}`}>
                                                            <li className="nav-item">
                                                                <ul className="nav">
                                                                    <li className="nav-item d-flex w-100"><Link
                                                                        className={`menu-nav-link ${location.search === '?activeTab=manage-profile' ? "active" : ''}`} role="button"
                                                                        title="Edit Profile"
                                                                        to={"/profile?activeTab=manage-profile"}>Edit
                                                                        Profile</Link></li>
                                                                    <li className="nav-item d-flex w-100"><Link
                                                                        className={`menu-nav-link ${location.search === '?activeTab=change-password' ? "active" : ''}`} role="button"
                                                                        title="Change Password"
                                                                        to={"/profile?activeTab=change-password"}>Change
                                                                        Password</Link></li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link className="menu-nav-link  nav-link" title="Sign Out"
                                                            to={"#"} onClick={() => handleLogout()}>
                                                            <span className="nav-icon-wrap">
                                                                <SignOutIcon />
                                                            </span>
                                                            <span className="nav-link-text">Sign Out</span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="simplebar-placeholder" style={{ width: "258px", height: "787px" }}></div>
                    </div>
                    <div className="simplebar-track simplebar-horizontal" style={{ visibility: "hidden" }}>
                        <div className="simplebar-scrollbar" style={{ width: "0px", display: "none" }}></div>
                    </div>
                    <div className="simplebar-track simplebar-vertical" style={{ visibility: "hidden" }}>
                        <div className="simplebar-scrollbar" style={{ height: "0px", display: "none" }}></div>
                    </div>
                </div>

            </div>
            <div id="hk_menu_backdrop" className="hk-menu-backdrop"></div>
        </div>
    )
}

export default Sidebar;