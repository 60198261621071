
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import thumbailImage from "../../assets/img/thumbnailImage.png"
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getDealsGraph, getJobs, getNews, getPatentGraph, getRegulations } from "../../store/datasetSlice";
import { useSelector } from "react-redux";
import { setPageTitle } from "../../store/commonSlice";
import { shortenedTitle } from "../../utils";
import { ShimmerThumbnail } from 'react-shimmer-effects';
import Charts from "../../component/Charts/Charts";
import { FormatPatentGraphData } from "../../component/Charts/ChartOption";
import DataNotFound from "../../component/DataNotFound";

function DatasetOverview() {
    const dispatch = useAppDispatch()
    const { jobs, news, regulations, dealsGraphDetails, patentGraphDetails } = useSelector(({ dataset }) => dataset);
    const { filter } = useSelector(({ common }) => common);
    const [dealsData, setDealsData] = useState([]);
    const [patentData, setPatentData] = useState([]);
    const [xaxis, setXaxis] = useState([]);
    const [pointStart, setPointStart] = useState(0);

    useMemo(() => {
        if (dealsGraphDetails?.data) {
            // Extract years from funds and investment
            const funds = dealsGraphDetails?.data?.fund?.map((detail) => detail?.year) || [];
            const investment = dealsGraphDetails?.data?.investment?.map((detail) => detail?.year) || [];

            // Combine and remove duplicates
            const combinedYears = Array.from(new Set([...funds, ...investment]));
            // Sort the combined years
            combinedYears.sort((a, b) => a - b);
            // Set the sorted xAxis
            setXaxis(combinedYears.slice(-6));
        }
        if (patentGraphDetails?.data) {
            const result: any = FormatPatentGraphData(patentGraphDetails?.data);
            setPointStart(parseInt(result?.startYear))
            result && delete result?.startYear;
            result && delete result?.endYear;
            setPatentData(result)
        }
    }, [dealsGraphDetails, patentGraphDetails]);

    useMemo(() => {
        const fundsData = xaxis?.map((year, index) => {
            const detail = dealsGraphDetails?.data?.fund.find(detail => detail.year === year);
            return detail ? detail.total : 0;
        });

        const investorData = xaxis?.map((year, index) => {
            const detail = dealsGraphDetails?.data?.investment.find(detail => detail.year === year);
            return detail ? detail.total : 0;
        });

        const seriesData = [
            {
                name: 'Funds',
                data: fundsData
            },
            {
                name: 'Investor',
                data: investorData
            }
        ];
        setDealsData(seriesData);
    }, [xaxis])

    const dealPointFormatter = function () {
        return '<span style="color:{series.color}">{series.name}</span>' +
            ': <b>{point.y}</b>';
    }
    const patentPointFormatter = function () {
        return `<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.1f}%</b>`;
    }
    const plotOptions = {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            borderWidth: 0, // Hide the border

            dataLabels: {
                enabled: false,
                format: '{point.name}: {y} %'
            },
            showInLegend: false,
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '75%'],
            size: '110%'
        }
    }
    useMemo(() => {
        let query = { companyId: filter?.company?._id || '', sectorId: filter?.sector?._id || '',categoryId:filter?.subsector?._id || '', from_date: filter?.from_date || '', to_date: filter?.to_date || '', keyword: filter?.keyword || '', year: filter?.year || '', page: 1, limit: 2 };
        dispatch(setPageTitle("NeoImpact - Dataset Overview"))
        dispatch(getPatentGraph(query));
        dispatch(getDealsGraph(query))
        dispatch(getNews(query));
        dispatch(getJobs(query));
        dispatch(getRegulations(query));

    }, [dispatch,filter])

    
    return (
        <>
            <section>
                <div className="row mt-4">
                    <div className="col-lg-4 col-md-6">
                        <div className="border bg-info bg-opacity-10 rounded p-4">
                            <h5 className="text-black fw-semibold mb-3">Patents</h5>
                            {
                                patentGraphDetails.loading ?
                                    <><ShimmerThumbnail height="200" /></> :


                                    patentGraphDetails?.data.length > 0 ?

                                        <>
                                            <Charts
                                                title={""}
                                                type={'area'}
                                                XAxis={[]}
                                                showLegend={false}
                                                height={"200px"}
                                                seriesData={patentData}
                                                pointStart={2000}
                                                pointFormatter={patentPointFormatter}
                                            />
                                            <div className="text-end mt-2"><Link to={"/dataset/patent"} className="text-decoration-underline">Show
                                                More</Link></div>
                                        </>
                                        :
                                        <>
                                            <DataNotFound title="No results" description="Unfortunately, no related patent could be found." />
                                        </>
                            }
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4 mt-md-0">
                        <div className="border bg-info bg-opacity-10 rounded p-4">
                            <h5 className="text-black fw-semibold mb-3">Deals</h5>
                            {
                                dealsGraphDetails.loading ?
                                    <><ShimmerThumbnail height="200" /></> :
                                    dealsData?.length > 0 && xaxis.length > 0 ?

                                        <>
                                            <Charts
                                                title={""}
                                                type={'column'}
                                                XAxis={xaxis}
                                                showLegend={false}
                                                height={"200px"}
                                                seriesData={dealsData}
                                                pointFormatter={dealPointFormatter}
                                            />

                                            <div className="text-end mt-2"><Link to={"/dataset/deals"} className="text-decoration-underline">Show
                                                More</Link></div> </>
                                        :
                                        <>
                                            <DataNotFound title="No results" description="Unfortunately, no related patent could be found." />
                                        </>
                            }

                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
                        <div className="border bg-info bg-opacity-10 rounded p-4">
                            <h5 className="text-black fw-semibold mb-3">Social Media</h5>
                            <Charts
                                type="pie"
                                title={''}
                                showPercentage={false}
                                plotOptions={plotOptions}
                                height={"200px"}
                                seriesData={[{ name: 'Environment', y: 30, color: 'red' }, { name: 'Social', y: 40, color: 'yellow' }, { name: 'Governance', y: 30, color: 'green' }]}
                            />
                            <div className="text-end mt-2"><Link to={"/dataset/social-media"} className="text-decoration-underline">Show
                                More</Link></div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-lg-4 col-md-6 mt-4 mt-md-0">
                        <div className="border bg-info bg-opacity-10 rounded p-4">
                            <h5 className="text-black fw-semibold mb-3">News</h5>
                            {news?.loading
                                ? Array(2).fill(<ShimmerThumbnail height="80" />)
                                :
                                news?.data?.data?.length > 0 ?
                                    <>{
                                        news?.data?.data?.map((News: any, index: number) => {
                                            return (
                                                <div className="d-flex align-items-center" key={`news-${index}`}>
                                                    <img src={thumbailImage} alt="img" />
                                                    <div className="ms-3">
                                                        <h6 className="fw-semibold text-black" dangerouslySetInnerHTML={{ __html: shortenedTitle(News?.title, 40) }}>
                                                        </h6>
                                                        <p dangerouslySetInnerHTML={{ __html: shortenedTitle(News?.summary, 50) }}></p>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }
                                        < div className="text-end mt-2"><Link to={"/dataset/news"} className="text-decoration-underline">Show
                                            More</Link></div>
                                    </>
                                    :
                                    <>
                                        <DataNotFound title="No results" description="Unfortunately, no related news could be found." />
                                    </>
                            }


                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="border bg-info bg-opacity-10 rounded p-4">
                            <h5 className="text-black fw-semibold mb-3">Job</h5>
                            {jobs?.loading
                                ? Array(2).fill(<ShimmerThumbnail height="80" />)
                                :
                                jobs?.data?.data?.length > 0 ?
                                    <>{
                                        jobs?.data?.data?.map((job: any, index: number) => {
                                            return (
                                                <div className="d-flex align-items-center" key={`news-${index}`}>
                                                    <img src={thumbailImage} alt="img" />
                                                    <div className="ms-3">
                                                        <h6 className="fw-semibold text-black" dangerouslySetInnerHTML={{ __html: shortenedTitle(job?.job_title, 40) }}>
                                                        </h6>
                                                        <p dangerouslySetInnerHTML={{ __html: shortenedTitle(job?.job_description, 50) }}></p>
                                                    </div>
                                                </div>
                                            )
                                        })

                                    }
                                        <div className="text-end mt-2"><Link to={"/dataset/jobs"} className="text-decoration-underline">Show
                                            More</Link></div>
                                    </>
                                    :
                                    <>
                                        <DataNotFound title="No results" description="Unfortunately, no related job could be found." />
                                    </>
                            }

                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
                        <div className="border bg-info bg-opacity-10 rounded p-4">
                            <h5 className="text-black fw-semibold mb-3">Regulations</h5>
                            {regulations?.loading
                                ? Array(2).fill(<ShimmerThumbnail height="80" />)
                                :
                                regulations?.data?.data?.length > 0 ?
                                    <>{
                                        regulations?.data?.data?.map((regulation: any, index: number) => {
                                            return (
                                                <div className="d-flex align-items-center" key={`news-${index}`}>
                                                    <img src={thumbailImage} alt="img" />
                                                    <div className="ms-3">
                                                        <h6 className="fw-semibold text-black" dangerouslySetInnerHTML={{ __html: shortenedTitle(regulation?.title, 40) }}>
                                                        </h6>
                                                        <p dangerouslySetInnerHTML={{ __html: shortenedTitle(regulation?.description, 50) }}></p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="text-end mt-2"><Link to={"/dataset/regulations"} className="text-decoration-underline">Show
                                    More</Link></div>
                                    </> :
                                    <>
                                        <DataNotFound title="No results" description="Unfortunately, no related regulation could be found." />
                                    </>
                            }

                        
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default DatasetOverview;
