import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineFileSearch } from "react-icons/ai";

export default function DataNotFound({title="test", description="", showButton=false, buttonText="", buttonRedirect="" }) {

    return (
        <div className="row">       
            <div className="404_sec my-lg-7 my-5">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-12 col-sm-8 text-center">
                        <div className="text-center">
                                <AiOutlineFileSearch size={70} className="text-black-50"/>
                            </div>
                            <h3 className="mt-2 fw-semibold text-secondary">{title}</h3>
                            <p className="mb-4 text-secondary">{description}</p>
                            {showButton && <Link to={buttonRedirect} className="btn btn-sm btn-primary" title={buttonText}>{buttonText}</Link>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}