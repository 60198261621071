
import React, { useEffect, useState } from 'react';

import Layout from "../../layouts/dashboard/Layout";
import DynamicMeta from "../../component/DynamicMeta";

function RegulationList() {

  return (
    <>
    <DynamicMeta />
        <Layout >
            
        <div className="hk-pg-wrapper pb-10"><div className="container-fluid px-4"><div className="hk-pg-body pt-2 pb-14">

        <section>
                        <div className="row">
                            <div className="col-12">
                               <div className="companies_filter border bg-info bg-opacity-10 rounded p-4">
                                <div className="col-12"><h5 className="text-black fw-semibold mb-3">Filters</h5></div>
                                <div className="row">
                                   <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0">
                                        <h6 className="fw-semibold text-black">Region</h6>
                                        <select className="form-select border fw-semibold text-black">
                                            <option value="1">North America</option>
                                            <option value="1">Europe</option>
                                            <option value="2">Asia Pacific</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0">
                                        <h6 className="fw-semibold text-black">Country</h6>
                                        <select className="form-select border fw-semibold text-black">
                                            <option value="1">U.S.</option>
                                            <option value="1">U.K.</option>
                                            <option value="2">India</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0">
                                        <h6 className="fw-semibold text-black">Sector</h6>
                                        <select className="form-select border fw-semibold text-black">
                                            <option value="1">Energy</option>
                                            <option value="1">Utilities</option>
                                            <option value="2">Materials</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0">
                                        <h6 className="fw-semibold text-black">Year</h6>
                                        <select className="form-select border fw-semibold text-black">
                                            <option value="1">2024</option>
                                            <option value="1">2023</option>
                                            <option value="2">2022</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0">
                                        <button className="btn company_filter_button w-100">ANALYZE</button>
                                    </div>
                                    </div>
                                </div> 
                                </div>
                            </div>
                        </section>    
                                    
                                
                                        
                    <section className="pt-5">
                            <div className="row">
                                <div className="col-lg-12">
                                   <div className="table-responsive">
                                       <table className="table table-bordered list-view">
  <thead>
    <tr>
      <th>S.N.</th>
      <th>Regulation Title</th>
      <th>Regulation Summary</th>
      <th>Issuer authority</th>
      <th>Geography</th>
      <th>Issue date</th>
      <th>Regulation Status</th>
      <th> </th>
      </tr>
     </thead>
     <tbody>
         <tr>
      <td>1</td>
      <td><a href="https://www.federalregister.gov/documents/2013/07/16/2013-17005/energy-conservation-program-energy-conservation-standards-for-residential-clothes-dryers-and-room">Energy Conservation Program: Energy Conservation Standards for Residential Clothes Dryers and Room Air Conditioners</a></td>
      <td>This final rule updates the energy conservation standards for room air conditioners by correcting previous errors. The original rule, published on April 21, 2011, and subsequent notices on August 24, 2011, mistakenly <span className="badge badge-soft-primary">Environment</span></td>
      <td>Federal Regulations (US)</td>
      <td>United States</td>
      <td>1/6/2014</td>
      <td>In Force</td>
      <td>Environment</td>
    </tr>
    <tr>
      <td>2</td>
      <td><a href="https://www.regulations.gov/document/EPA-R06-OAR-2014-0821-0006">Air Quality Regulation: State of Louisiana</a></td>
      <td>Under the Louisiana Environmental Quality Act and the Administrative Procedure Act, the secretary has updated the Air Quality Division Regulations (LAC 33:11I.517). The revision allows the Department of <span className="badge badge-soft-primary">Environment</span></td>
      <td>Louisiana department of Environmental Quality </td>
      <td>State of Louisiana</td>
      <td>4/20/2016</td>
      <td>In Force</td>
      <td>Environment</td>
    </tr>
    <tr>
      <td>3</td>
      <td><a href="https://www.regulations.gov/document/EPA-HQ-OPP-2013-0250-0015">Preliminary Risk Assessment for Registration Review of Propazine</a></td>
      <td>Preliminary risk assessment for the herbicide propazine, conducted by the EPA's Office of Chemical Safety and Pollution Prevention. It evaluates the environmental fate and ecological risks associated with <span className="badge badge-soft-primary">Environment</span></td>
      <td>UNITED STATES ENVIRONMENTAL PROTECTION AGENCY</td>
      <td>United States</td>
      <td>4/5/2016</td>
      <td>In Force</td>
      <td>Environemnt</td>
    </tr>
    <tr>
      <td>4</td>
      <td><a href="https://www.regulations.gov/document/EPA-R06-OAR-2014-0821-0009">Air Quality Regulation: State of Louisiana (Revised)</a></td>
      <td>The regulation involves revisions to Louisiana's State Implementation Plan (SIP) for air quality, specifically addressing updates to the Louisiana Administrative Code (LAC) concerning emissions. Key changes include the <span className="badge badge-soft-primary">Environment</span></td>
      <td>Louisiana department of Environmental Quality </td>
      <td>State of Louisiana</td>
      <td>4/20/2016</td>
      <td>In Force</td>
      <td>Environment</td>
    </tr>
    <tr>
      <td>5</td>
      <td><a href="https://www.epa.gov/laws-regulations/summary-clean-air-act">Clean Air Act</a></td>
      <td>The Clean Air Act (CAA) is a major federal law that oversees air pollution from both stationary and mobile sources. It gives the EPA the authority to set National Ambient Air Quality Standards (NAAQS) to safeguard <span className="badge badge-soft-primary">Environment</span></td>
      <td>UNITED STATES ENVIRONMENTAL PROTECTION AGENCY</td>
      <td>United States</td>
      <td>Issued 1970, Last Updated July 31, 2024</td>
      <td>In Force</td>
      <td>Environment</td>
    </tr>
    <tr>
      <td>6</td>
      <td><a href="https://www.epa.gov/laws-regulations/summary-occupational-safety-and-health-act">Occupational Safety and Health Act</a></td>
      <td>Occupational Safety and Health Act to promote workplace safety and protect workers. The Act aims to ensure that employers provide a work environment free from identified hazards like toxic chemicals, excessive <span className="badge badge-soft-primary">Environment</span></td>
      <td>UNITED STATES ENVIRONMENTAL PROTECTION AGENCY</td>
      <td>United States</td>
      <td>Issued 1976, Last updated on September 9, 2024</td>
      <td>In Force</td>
      <td>Social</td>
    </tr>
    <tr>
      <td>7</td>
      <td><a href="https://www.epa.gov/laws-regulations/summary-resource-conservation-and-recovery-act">Resource Conservation and Recovery Act</a></td>
      <td>The Resource Conservation and Recovery Act (RCRA) allows the EPA to regulate hazardous waste throughout its lifecycle and manage non-hazardous waste. The 1986 amendments also address environmental risks <span className="badge badge-soft-primary">Environment</span></td>
      <td>UNITED STATES ENVIRONMENTAL PROTECTION AGENCY</td>
      <td>United States</td>
      <td>Issued 1976, Last updated on July 31, 2024</td>
      <td>In Force</td>
      <td>Environment</td>
    </tr>
    <tr>
      <td>8</td>
      <td><a href="https://www.epa.gov/laws-regulations/summary-chemical-safety-information-site-security-and-fuels-regulatory-relief-act">Chemical Safety Information, Site Security and Fuels Regulatory Relief Act</a></td>
      <td>The Chemical Safety Information, Site Security and Fuels Regulatory Relief Act updates reporting requirements under Section 112(r) of the Clean Air Act. It addresses two main areas: flammable fuels and public <span className="badge badge-soft-primary">Environment</span></td>
      <td>UNITED STATES ENVIRONMENTAL PROTECTION AGENCY</td>
      <td>United States</td>
      <td>Issued 1996, Last updated on June 12, 2024</td>
      <td>In Force</td>
      <td>Environment</td>
    </tr>
    <tr>
      <td>9</td>
      <td><a href="https://www.epa.gov/laws-regulations/summary-executive-order-12898-federal-actions-address-environmental-justice">Federal Actions to Address Environmental Justice in Minority Populations and Low-Income Populations</a></td>
      <td>Issued by President Bill Clinton in 1994, Executive Order 12898 mandates that federal actions consider their impact on minority and low-income communities. It directs agencies to address and reduce <span className="badge badge-soft-primary">Environment</span></td>
      <td>UNITED STATES ENVIRONMENTAL PROTECTION AGENCY</td>
      <td>United States</td>
      <td>Issues 1994, Last updated on June 18, 2024</td>
      <td>In Force</td>
      <td>Environment and Social</td>
    </tr>
    <tr>
      <td>10</td>
      <td><a href="https://www.epa.gov/laws-regulations/summary-chemical-safety-information-site-security-and-fuels-regulatory-relief-act">Chemical Safety Information, Site Security and Fuels Regulatory Relief Act</a></td>
      <td>The Chemical Safety Information, Site Security, and Fuels Regulatory Relief Act updates the reporting and information-sharing requirements of Section 112(r) of the Clean Air Act. It addresses two main areas: <span className="badge badge-soft-primary">Environment</span></td>
      <td>UNITED STATES ENVIRONMENTAL PROTECTION AGENCY</td>
      <td>United States</td>
      <td>Issues 1999, Last updated on June 12, 2024</td>
      <td>In Force</td>
      <td>Environment</td>
    </tr>
  </tbody>
</table>
                                   </div>
                                </div>
                            </div>
                    </section>
   

            </div></div></div>
       </Layout>
    </> 
  );
};

export default RegulationList;
