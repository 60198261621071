
import React, { useEffect, useState } from 'react';
import regional_split from "../../assets/img/regional-split.png";
import regional_industry from "../../assets/img/regulations-by-Industry.png";
import regional_growth from "../../assets/img/YOY-growth-regulation.png";
import Layout from "../../layouts/dashboard/Layout";
import DynamicMeta from "../../component/DynamicMeta";

function RegulationDashboard() {

  return (
    <>
    <DynamicMeta />
        <Layout >
            
        <div className="hk-pg-wrapper pb-10"><div className="container-fluid px-4"><div className="hk-pg-body pt-2 pb-14">

        <section className="dashboard-industry">
                <div className="row">
                      <div className="col-lg-4">
                        <div className="companies_filter border bg-info bg-opacity-10 rounded p-4 h-100">
                            <h5 className="text-black fw-semibold mb-3">Regulations <strong>7789</strong>
                        </h5>
                        <div className="row">
                          <div className="col-lg-6">
                            <h6 className="fw-semibold text-black">Industry </h6>
                            <select className="form-select border fw-semibold text-black">
                              <option value="energy">Energy</option>
                              <option value="regulation-dashboard.html">Utilities</option>
                              <option value="regulation-dashboard.html">All</option>
                              <option value="regulation-dashboard.html">Materials</option>
                            </select>
                          </div>
                          <div className="col-lg-6">
                            <h6 className="fw-semibold text-black">Sub Industry</h6>
                            <select className="form-select border fw-semibold text-black">
                              <option value="regulation-dashboard.html">Coal & Consumable Fuels</option>
                              <option value="regulation-dashboard.html">Aluminum</option>
                              <option value="regulation-dashboard.html">Commodity Chemicals</option>
                              <option value="regulation-dashboard.html">Construction Materials</option>
                              <option value="regulation-dashboard.html">Copper</option>
                              <option value="regulation-dashboard.html">Diversified Chemicals</option>
                              <option value="regulation-dashboard.html">Diversified Metals & Mining</option>
                              <option value="regulation-dashboard.html">Electric Utilities</option>
                              <option value="regulation-dashboard.html">Fertilizers & Agricultural Chemicals</option>
                              <option value="regulation-dashboard.html">Forest Products</option>
                              <option value="regulation-dashboard.html">Gas Utilities</option>
                              <option value="regulation-dashboard.html">Gold</option>
                              <option value="regulation-dashboard.html">Independent Power Producers & Energy Traders</option>
                              <option value="regulation-dashboard.html">Industrial Gases</option>
                              <option value="regulation-dashboard.html">Integrated Oil & Gas</option>
                              <option value="regulation-dashboard.html">Metal, Glass and Plastic Containers</option>
                              <option value="regulation-dashboard.html">Multi-Utilities</option>
                              <option value="regulation-dashboard.html">Oil & Gas Drilling</option>
                              <option value="regulation-dashboard.html">Oil & Gas Equipment & Services</option>
                              <option value="regulation-dashboard.html">Oil & Gas Exploration & Production</option>
                              <option value="regulation-dashboard.html">Oil & Gas Refining & Marketing</option>
                              <option value="regulation-dashboard.html">Oil & Gas Storage & Transportation</option>
                              <option value="regulation-dashboard.html">Paper & Plastic Packaging Product & Materials</option>
                              <option value="regulation-dashboard.html">Paper Products</option>
                              <option value="regulation-dashboard.html">Precious Metals & Minerals</option>
                              <option value="regulation-dashboard.html">Renewable Electricity</option>
                              <option value="regulation-dashboard.html">Silver</option>
                              <option value="regulation-dashboard.html">Speciality Chemicals</option>
                              <option value="regulation-dashboard.html">Steel</option>
                              <option value="regulation-dashboard.html">Water Utilities</option>
                              <option value="regulation-dashboard.html">All</option>
                            </select>
                          </div>
                          <div className="col-lg-6 mt-3">
                            <h6 className="fw-semibold text-black">Region</h6>
                            <select className="form-select border fw-semibold text-black">
                              <option value="0">U.S.</option>
                              <option value="1">South Africa</option>
                              <option value="2">U.K.</option>
                              <option value="2">All</option>
                              <option value="2">Australia</option>
                            </select>
                          </div>
                          <div className="col-lg-6 mt-3">
                            <h6 className="fw-semibold text-black">Year</h6>
                            <select className="form-select border fw-semibold text-black">
                              <option value="1">2021</option>
                              <option value="2">2022</option>
                              <option value="2">2023</option>
                              <option value="3">2024</option>
                            </select>
                          </div>

                            <div className='col-12 mt-3'>
                                <button className='btn btn-outline-secondary'>  
                                  <svg className='me-1' stroke="currentColor" style={{ verticalAlign: "-3px" }}  fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"></path></svg>
                                  Save to Watchlist
                                </button>
                            </div>


                        </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="companies_filter border bg-white rounded p-4 h-100">
                            <h5 className="text-black fw-semibold mb-3">Regulations Overview by Industry </h5>
                            <div className="d-flex pb-2"><div className="companies_filter_ind">Approved</div> <span className="ms-5 fw-semibold badge badge-soft-primary">1260</span></div>
                            <div className="d-flex py-2"><div className="companies_filter_ind">In Process</div> <span className="ms-5 fw-semibold badge badge-soft-primary">577</span></div>
                            <div className="d-flex py-2"> <div className="companies_filter_ind">Archived</div> <span className="ms-5 fw-semibold badge badge-soft-primary">3994+</span></div>
                            <div className="d-flex py-2"><div className="companies_filter_ind">Environment</div> <span className="ms-5 fw-semibold badge badge-soft-primary">766</span></div>
                            <div className="d-flex py-2"><div className="companies_filter_ind">Social</div> <span className="ms-5 fw-semibold badge badge-soft-primary">189</span></div>
                            <div className="d-flex pt-2"><div className="companies_filter_ind">Governance</div> <span className="ms-5 fw-semibold badge badge-soft-primary">512</span></div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="border bg-white rounded p-4 h-100">
                          <div>
                            <h5 className="text-black fw-semibold mb-3">Recent Activity</h5>
                            <p>Energy Conservation Program</p>
                            <hr className="my-2" />
                            <p>Air Quality Regulation</p>
                            <hr className="my-2" />
                            <p>Preliminary Risk Assessment</p>
                            <hr className="my-2" />
                            <p>Energy Regulation</p>
                            <hr className="my-2" />
                            <p>Clean Air Act</p>
                            <hr className="my-2" />
                            <p>Occupational Safety and Health Act</p>
                            <a href="/regulation-list"><button className="btn btn-outline-primary mt-3">Go to List View</button></a>
                          </div>
                        </div>
                      </div>
                    </div>
            </section>
            <section className=" pt-3 pt-lg-5">
                <div className="row">
                  <div className="col-lg-4">
                    <div className=" border bg-white rounded p-4 h-100">
                        <h5 className="text-black fw-semibold mb-3">Regulations by Industry</h5>
                        <div className="text-center">
                            <img src={regional_industry} alt="img" />
                            <div className="mt-2">
                            <a href="/regulation-list" className="text-decoration-underline">Show More</a>
                            </div>
                        </div>
                        </div>
                    </div>
                   <div className="col-lg-4">
                    <div className=" border bg-white rounded p-4 h-100">
                    <h5 className="text-black fw-semibold mb-3">Regional Distribution</h5>
                    <div className="text-center">
                      <img src={regional_split} alt="img" width="200" />
                      <div className="mt-2">
                        <a href="/regulation-list" className="text-decoration-underline">Show More</a>
                      </div>
                    </div>
                  </div>
              </div>
                  <div className="col-lg-4">
                    <div className=" border bg-white rounded p-4 h-100">
                    <h5 className="text-black fw-semibold mb-3">Regulations by Industry</h5>
                    
                    <div className="text-center">
                        <img src={regional_growth} alt="img" />
                    <div className="mt-2">
                      <a href="/regulation-list" className="text-decoration-underline">Show More</a>
                    </div>
                </div>
                </div>
              </div>
          </div>
                
            </section>


            </div></div></div>
       </Layout>
    </> 
  );
};

export default RegulationDashboard;
