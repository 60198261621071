import React from 'react';
import { Modal } from 'react-bootstrap';
import SigninForm from '../Forms/SigninForm';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { handleSignInModel } from '../../store/commonSlice';

export default function SigninModel() {
    const dispatch = useAppDispatch();
    const { signinShow } = useSelector(({ common }) => common)
    // const { isLoggedIn } = useSelector(({ auth }) => auth)

    return (
        <Modal show={signinShow } onHide={() => dispatch(handleSignInModel(false))} backdrop="static" keyboard={false} dialogClassName='mw-375p mx-auto modal-dialog-centered' contentClassName='p-4'>
            <button id="LFCloseBtn" onClick={() => dispatch(handleSignInModel(false))} type="button" className="btn-close btn-close-corner-normal" aria-label="Close" title='Close'> </button>
            <Modal.Body>
                <SigninForm />
            </Modal.Body>
        </Modal>
    );
}
