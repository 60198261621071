import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { updatePassword } from "../../store/authSlice";
import { setPageTitle } from "../../store/commonSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ButtonSpinner } from "../../component/Button";
import { useAppDispatch } from "../../hooks/useAppDispatch";

const UpdatePasswordForm = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { token , email } = useParams();


    // Set page title using useEffect to avoid calling dispatch during rendering
    useEffect(() => {
        dispatch(setPageTitle("NeoImpact - Sign In"));
    }, [dispatch]);

    const { loader, serverError, successMessage } = useSelector(({ common }) => common);

    const defaultValues = {
        email: email,
        token: token,
        password: '',
        password_confirmation: ''
    };
    const [detail, setDetails] = useState(defaultValues);
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        // if (detail?.current_password ==== "") {
        //     setErrorMessage('Please enter current password');
        //     return;
        // }
        if (detail?.password === "") {
            setErrorMessage('Please enter new password');
            return;
        }

        if (detail?.password_confirmation === "") {
            setErrorMessage('Please enter confirm password');
            return;
        }
        if (detail?.password_confirmation !== detail?.password) {
            setErrorMessage('New password  and  confirm password is not match');
            return;
        }
        dispatch(updatePassword({...detail,email:email}));
        if (successMessage) {
            navigate('/sign-in')
        }
        // setDetails(defaultValues);

    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleOnChange = (event) => {
        const { name, value, type, checked } = event.target;
        setDetails((oldDetail) => ({
            ...oldDetail,
            [name]: type === 'checkbox' ? checked : value
        }));
    };


    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="form-label-group mb-4">
                    <h4 className="my-0">Update Password</h4>
                </div>
                <div className="row gx-3">
                    {(errorMessage || serverError) && <p className='text-danger p-xs mb-2 text-center'>{serverError ? serverError : errorMessage}</p>}
                    {successMessage && <p className='text-success p-xs mb-2 text-center'>{successMessage}</p>}
                </div>
                {/* <div className="form-floating mb-4" style={{ position: "relative" }}>
                    <input type="text" disabled name="email" className="form-control" id="floatingEmail"
                        placeholder="Email" value={email} required onChange={handleOnChange} />
                    <label htmlFor="floatingEmail">Email</label>
                </div> */}
                <div className="form-floating mb-4" style={{ position: "relative" }}>
                    <input type={showPassword ? 'text' : 'password'} name="password" className="form-control" id="floatingPassword"
                        placeholder="New Password" value={detail?.password} required onChange={handleOnChange} />
                    <Link to="#" onClick={handleTogglePassword} className="input-suffix text-muted" title={showPassword ? "Hide Password" : "View Password"} style={{ position: "absolute", top: "16px", right: "5px" }}>
                        {detail?.password !== "" && (showPassword ? <FaEyeSlash /> : <FaEye />)}
                    </Link>
                    <label htmlFor="floatingPassword">New Password</label>
                </div>
                <div className="form-floating mb-4" style={{ position: "relative" }}>
                    <input type={showPassword ? 'text' : 'password'} name="password_confirmation" className="form-control" id="floatingPassword"
                        placeholder="Confirm Password" value={detail?.password_confirmation} required onChange={handleOnChange} />
                    <label htmlFor="floatingPassword">Confirm Password</label>
                </div>
                <div className="mb-4">
                    <button className="as_info_btn  btn-uppercase btn-block" disabled={loader} type="submit"> <ButtonSpinner />Update Password</button>
                </div>
            </form>
        </>
    );
};

export default UpdatePasswordForm;
