import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { validateEmail } from "../../utils/constant";
import { signIn } from "../../store/authSlice";
import { handleSignInModel, signInRedirect, signUpRedirect, setPageTitle } from "../../store/commonSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ButtonSpinner } from "../../component/Button";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import SignupModel from "../Models/SignupModel";
// import { ToastContainer, toast } from 'react-toastify';


export default function SigninForm() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    // const notify = (status: string, msg: string) => toast(msg);

    // Set page title using useEffect to avoid calling dispatch during rendering
    useEffect(() => {
        dispatch(setPageTitle("NeoImpact - Sign In"));
    }, [dispatch]);

    const { loader, serverError, successMessage } = useSelector(({ common }) => common);
    const { isVerified } = useSelector(({ auth }) => auth);

    const defaultValues = {
        email: '',
        password: '',
        remember: false
    };
    const [detail, setDetails] = useState(defaultValues);
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setErrorMessage("");
        if (detail?.email === "") {
            setErrorMessage('What\'s your email?');
            return;
        }
        if (!validateEmail(detail?.email)) {
            setErrorMessage('Please enter a valid email address');
            return;
        }
        if (detail?.password === "") {
            setErrorMessage('Provide your password');
            return;
        }
        dispatch(signIn({ email: detail.email, password: detail?.password }))
            .then((response) => {
                dispatch(handleSignInModel(false))
            })
            .catch((error) => {

            })
            ;

    };
    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleOnChange = (event: any) => {
        const { name, value, type, checked } = event.target;
        setDetails((oldDetail) => ({
            ...oldDetail,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    // {(errorMessage || serverError) && notify('errror', serverError ? serverError : errorMessage)}
    // {(successMessage && isVerified) && notify('success', successMessage)}
    // {!isVerified && notify('errror', 'Verify your account first')}

   
    return (
        <>
        <form onSubmit={handleSubmit}>
            <div className="form-label-group mb-4">
                <h4 className="my-0">Sign in</h4>
                <a href={"#"} onClick={() => dispatch(signUpRedirect(navigate,false))} className="text-decoration-underline">Create free account</a>
            </div>
            <div className="row gx-3">
                {(errorMessage) && <p className='text-danger p-xs mb-2 text-center'>{errorMessage}</p>}
            </div>

            <div className="form-floating mb-4">
                <input type="email" name="email" className="form-control" id="floatingInput"
                    placeholder="name@example.com" value={detail?.email} required onChange={handleOnChange} />
                <label htmlFor="floatingInput">Email address</label>
            </div>
            <div className="form-floating mb-4" style={{ position: "relative" }}>
                <input type={showPassword ? 'text' : 'password'} name="password" className="form-control" id="floatingPassword"
                    placeholder="Password" value={detail?.password} required onChange={handleOnChange} />
                <Link to="#" onClick={handleTogglePassword} className="input-suffix text-muted" title={showPassword ? "Hide Password" : "View Password"} style={{ position: "absolute", top: "16px", right: "5px" }}>
                    {detail?.password !== "" && (showPassword ? <FaEyeSlash /> : <FaEye />)}
                </Link>
                <label htmlFor="floatingPassword">Password</label>
            </div>
            <div className="form-check form-check-sm mb-4">
                <input type="checkbox" className="form-check-input" name="remember" id="remember" checked={detail?.remember} onChange={handleOnChange} />
                <label className="form-check-label text-muted fs-7" htmlFor="remember">
                    Keep me logged in
                </label>
                <Link to={"/forgot-password"} className="float-end font-s-12">Forgot Password ?</Link>
            </div>
            <div className="mb-4">
                <button className="as_info_btn  btn-uppercase btn-block" disabled={loader} type="submit"><ButtonSpinner /> Log In</button>
            </div>
            <p className="font-s-13 mb-2 text_purple2">
                Support Email : <Link className="font-s-11" to={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>
            </p>
            <p className="font-s-13  text_purple2">
                Support Phone: <Link to={`tel:${process.env.REACT_APP_SUPPORT_PHONE}`}>{process.env.REACT_APP_SUPPORT_PHONE}</Link>
            </p>
        </form>
        <SignupModel />
        </>
    )
}