import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetcherGet } from "../utils/fetcher";
import {  setServerError } from "./commonSlice";
import { AppDispatch } from "./store";


const initialState = {
    datasetDetail: {},
    datasets: ['datasets 1','datasets 2','datasets 3'],    
    patents: { data: [], loading: false },
    deals: { data: [], loading: false },
    jobs: { data: [], loading: false },
    news: { data: [], loading: false },
    regulations: { data: [], loading: false },
    jobsGraphDetails: { data: [], loading: false },
    dealsGraphDetails: { data: [], loading: false },
    patentGraphDetails: { data: [], loading: false },

};

const datasetSlice = createSlice({
    name: "dataset",
    initialState,
    reducers: {
        setDatasetDetails: (state, action :PayloadAction<any>) => {
            state.datasetDetail = action.payload;
        },
        setDatasets: (state, action :PayloadAction<any>) => {
            state.datasets = action.payload;
        },
        setPatents: (state, action: PayloadAction<any>) => {
            state.patents = action.payload;
        },
        setdDeals: (state, action: PayloadAction<any>) => {
            state.deals = action.payload;
        },
        setJobs: (state, action: PayloadAction<any>) => {
            state.jobs = action.payload;
        },
        setNews: (state, action: PayloadAction<any>) => {
            state.news = action.payload;
        },
        setRegulations: (state, action: PayloadAction<any>) => {
            state.regulations = action.payload;
        },
        setJobsGraphDetails: (state, action: PayloadAction<any>) => {
            state.jobsGraphDetails = action.payload;
        },
        setDealsGraphDetails: (state, action: PayloadAction<any>) => {
            state.dealsGraphDetails = action.payload;
        },
        setPatentGraphDetails: (state, action: PayloadAction<any>) => {
            state.patentGraphDetails = action.payload;
        },
        setLoading: (state, action: PayloadAction<{ key: string; loading: boolean }>) => {
            state[action.payload.key].loading = action.payload.loading;
        }
    },
});

export const { setDatasetDetails, setDatasets,setPatents,setdDeals,setJobs,setNews,setRegulations,setJobsGraphDetails,setDealsGraphDetails,setPatentGraphDetails,setLoading } = datasetSlice.actions;
export default datasetSlice.reducer;



export function getPatents({keyword,companyId,page,limit}) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'patents', loading: true }));
        fetcherGet(`patents?keyword=${keyword}&company_id=${companyId}&page=${page}&limit=${limit}`)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setPatents({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoading({ key: 'patents', loading: false }));
            });
    };
}

export function getDeals({companyId,page,limit}) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'deals', loading: true }));
        fetcherGet(`get-company-deals?company_id=${companyId}&page=${page}&limit=${limit}`)
            .then((response: any) => {
                if (response[0] === 200) {
                    dispatch(setdDeals({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoading({ key: 'deals', loading: false }));
            });
    };
}

export function getJobs({companyId,from_date,to_date,page,limit}) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'jobs', loading: true }));
        fetcherGet(`get-company-jobs?company_id=${companyId}&from_date=${from_date}&to_date=${to_date}&page=${page}&limit=${limit}`)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setJobs({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoading({ key: 'jobs', loading: false }));
            });
    };
}

export function getNews({companyId,year,page,limit}) {

    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'news', loading: true }));
        fetcherGet(`get-company-news?company_id=${companyId}&year=${year}&page=${page}&limit=${limit}`)
            .then((response: any) => {
                if (response[0] === 200) {
                    dispatch(setNews({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoading({ key: 'news', loading: false }));

            });
    };
}

export function getRegulations({companyId,page,limit}) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'regulations', loading: true }));
        fetcherGet(`regulations?company_id=${companyId}&page=${page}&limit=${limit}`)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setRegulations({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoading({ key: 'regulations', loading: false }));

            });
    };
}


export function getJobsGraphDetails({companyId,month,year}) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'jobsGraphDetails', loading: true }));
        fetcherGet(`jobs-graph-data?company_id=${companyId}&year=${year}&month=${month}`)
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(setJobsGraphDetails({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoading({ key: 'jobsGraphDetails', loading: false }));
            });
    };
}

export function getDealsGraph({companyId,sectorId,categoryId}) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'dealsGraphDetails', loading: true }));
        fetcherGet(`get-deals-graph-data?sector_id=${sectorId}&subsector_id=${categoryId}&company_id=${companyId}`)
            .then((response: any) => {
                if (response[0] === 200) {
                    dispatch(setDealsGraphDetails({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoading({ key: 'dealsGraphDetails', loading: false }));
            });
    };
}
export function getPatentGraph({companyId,sectorId,categoryId}) {
    return async (dispatch: AppDispatch) => {

        dispatch(setLoading({ key: 'patentGraphDetails', loading: true }));
        fetcherGet(`get-patent-graph-data?sector_id=${sectorId}&subsector_id=${categoryId}&company_id=${companyId}`)
            .then((response: any) => {
                if (response?.status === 200) {
                    dispatch(setPatentGraphDetails({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                if (error?.response?.status === 400) {
                    dispatch(setServerError(error?.response?.data?.error))
                } else {
                    dispatch(setServerError(error?.message))
                }
            }).finally(() => {
                dispatch(setLoading({ key: 'patentGraphDetails', loading: false }));
            });
    };
}