import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { useSelector } from "react-redux";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { sendAccessCodeLink, verifyOtp } from "../../store/authSlice";
import check_png from '../../assets/img/check_png.png';
import { toast } from "react-toastify";
export default function AccessForm() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { user } = useSelector(({ auth }) => auth)
    const [sendAccessCode, setSendAccessCode] = useState(true);
    const [sendAccessLink, setSendAccessLink] = useState(false);

    const [otp, setOtp] = useState('');
    const [resendAccessLink, setResendAccessLink] = useState(false);
    const [resendAccessCode, setResendAccessCode] = useState(false);

    const [resendLoading, setResendLoading] = useState(false);
    const { loader, serverError, successMessage, navigateTo } = useSelector(({ common }) => common);
    const [isLoading, setIsLoading] = useState(false);
    // const [showAccessCodeForm, setShowAccessCodeForm] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const handleSendAccess = () => {
        if (sendAccessCode) {
            verifyAccessCode();
        } else {
            handleSendAccessCodeLink("resend-otp")
        }

        return;
    }

    const handleSendAccessCodeLink = async (type = "resend-otp") => {
        if (type == "resend-email" || type == "resend-otp") {
            setResendLoading(true);
        } else {
            setIsLoading(true);
        }


        let formData = {
            email: user?.email, type: type
        };
        try {
            dispatch(sendAccessCodeLink(formData))
                .then((response) => {
                    if (type == "resend-email") {
                        if (sendAccessLink) {
                            setResendAccessLink(true);
                            setResendLoading(false)
                        }
                        setSendAccessLink(true);
                        setSendAccessCode(false);
                        setResendAccessCode(false)
                    }
                    else if (type == "resend-otp") {
                        if (sendAccessCode) {
                            setResendAccessCode(true);
                            setResendLoading(false)
                        }
                        setSendAccessCode(true);
                        setSendAccessLink(false);
                        setResendAccessLink(false);

                    }
                }).catch((error) => {
                    setResendLoading(false)
                    setIsLoading(false);

                })



        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong! Try after sometime.")
        }
        if (type == "resend-email" || type == "resend-code") {
            setResendLoading(false);
        } else {
            setIsLoading(false);
        }
    }


    const verifyAccessCode = async () => {

        setErrorMessage("");
        if (otp == "") {
            setErrorMessage('Please enter the OTP ');
            return;
        }
        let formData = {
            email: user?.email, otp: otp
        };
        dispatch(verifyOtp(formData));

    }

    const handleResendAccessCode = () => {
        setIsLoading(false)
        setErrorMessage("")
        setOtp("")
        setSendAccessCode(false)
    }
    useEffect(() => {
        if (navigateTo != '/') {
            navigate(navigateTo)
        }
    }, [navigateTo])
    return <>
        <div className="form-label-group mb-4">
            <h4 className="my-0">Get Access to your account</h4>
        </div>
        <div className="row gx-3">
            {/* {errorMessage && <p className='text-danger p-xs mb-2 text-center'>{errorMessage}</p>} */}
            {/* {successMessage && <p className='text-success p-xs mb-2 text-center'>{successMessage}</p>} */}
        </div>

        {/* {showAccessCodeForm ? */}

        {sendAccessCode ?
            <div className="text-center">
                <p>An email with the code has been sent to</p>
                <p className="mb-2">{user?.email}</p>
                <div className="col-lg-12">
                    <div className="my-4">
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={5}
                            renderSeparator={<span className="mx-2">-</span>}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={"otp_input fs-3"}
                            containerStyle={"d-inline"}
                        />
                    </div>
                </div>

                {resendAccessCode ? <p className="mb-2 text-success"> Sent! </p> : <p className="mb-2"><a href="#" role="button" className="link" onClick={() => handleSendAccessCodeLink("resend-otp")}> {resendLoading ? <Spinner /> : "Did not get the code?"} </a></p>}

            </div>


            : <> {sendAccessLink ?
                <div className="card card-body text-center">
                    <img className="align-self-center my-2" src={check_png} width={20} height={20} />
                    <p>Please check your email.</p>
                    <p>We've sent you a link to login.</p>
                    {resendAccessLink ? <p className="text-success"> Sent! </p> : <p className="my-2"><a href="#" role="button" className="link" onClick={() => handleSendAccessCodeLink("resend-email")}>{resendLoading ? <Spinner /> : "Resend"}</a></p>}
                </div>
                : <div className="col-lg-12">
                    <div className="form-floating mb-4">
                        <input className="form-control" placeholder="Business Email Address*" value={user?.email}
                            name="email"
                            disabled
                            type="text"
                        />
                        <label>Business Email Address</label>
                    </div>
                </div>}

            </>}

        {/* } */}

        {!sendAccessLink && <button id="ALBtn" disabled={loader} onClick={handleSendAccess} className="as_info_btn  btn-uppercase btn-block mb-4" title={sendAccessCode ? "Continue with access code" : "Send me an access link"}>{loader ? <Spinner /> : (sendAccessCode ? "Continue with access code" : "Send me an access link")}</button>}

        <p>If you are having problems with the access {sendAccessCode ? " code" : " link"},
            you can be sent an <a id={sendAccessCode ? "access-code" : " access-link"} role="button" className={loader ? "link-disabled" : "link"} onClick={() => { sendAccessCode ? handleSendAccessCodeLink("resend-email") : handleSendAccessCodeLink("resend-otp") }}>access {!sendAccessCode ? " code" : " link"}</a> instead.</p>

        <hr></hr>

        <p className="font-s-13 mb-2">
            Support Email: <Link to={"mailto:" + process.env.REACT_APP_SUPPORT_EMAIL} title="Email">{process.env.REACT_APP_SUPPORT_EMAIL}</Link>
        </p>
        <p className="font-s-13">
            Support Phone: <Link to={"tel:" + process.env.REACT_APP_SUPPORT_PHONE} title='Contact number'>{process.env.REACT_APP_SUPPORT_PHONE}</Link>
        </p>
    </>;
}