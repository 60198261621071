
import React, { useEffect, useState } from "react";
import DynamicMeta from "../../component/DynamicMeta";
import Layout from "../../layouts/dashboard/Layout";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import thumbnail_logo from "../../assets/img/thumbnailImage.png"
import FilterBy from "../../component/FilterBy";
import Monitors from "../../component/Monitor";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCompanyDetail } from "../../store/companySlice";
import PatentRow from "../../component/Rows/Patent";
import JobRow from "../../component/Rows/Jobs";
import DealsRow from "../../component/Rows/Deals";
import NewsRow from "../../component/Rows/News";
import { formatDate } from "../../utils/constant";
import { RiskRating } from "../../component/RiskRating";
import {ShimmerTitle} from 'react-shimmer-effects'; 
function CompanyDetail() {
    const dispatch = useAppDispatch();
    const { companyId } = useParams();
    const [page, setPage] = useState('');
    const { company } = useSelector(({ company }) => company);
    const defaultComponentsOrder = ['CompanyFilter', 'SectorFilter', 'SubSectorFilter'];
    const [componentsOrder, setComponentsOrder] = useState(defaultComponentsOrder);
    const [filterBy, setFilterBy] = useState('Company');

    useEffect(() => {
        switch (page) {
            case 'patent':
                setComponentsOrder(['KeywordFilter', 'CompanyFilter']);
                setFilterBy("Patent")
                break;
            case 'deals':
                setComponentsOrder(['CompanyFilter', 'SectorFilter', 'SubSectorFilter']);
                setFilterBy("Deals")
                break;
            case 'jobs':
                setComponentsOrder(['CompanyFilter', 'SectorFilter', 'SubSectorFilter', 'FromYearFilter', 'ToYearFilter']);
                setFilterBy("Jobs")
                break;
            case 'regulations':
                setComponentsOrder(['KeywordFilter', 'RegionFilter', 'CountryFilter']);
                setFilterBy("Regulations")
                break;
            case 'news':
                setComponentsOrder(['CompanyFilter', 'SectorFilter', 'FromYearFilter', 'ToYearFilter']);
                setFilterBy("News")
                break;
            default:
                setFilterBy("Company")
                break;
        }
    }, [page]);
    useEffect(() => {
        dispatch(getCompanyDetail(companyId));
    }, [companyId])

    const handleTabChange = (page: string) => {
        setPage(page)
    }
    return (
        <>
            <DynamicMeta />
            <Layout >
                <div className="hk-pg-wrapper pb-10">
                    <div className="container-fluid px-4">
                        <div className="hk-pg-body pt-2">
                            <FilterBy filterBy={filterBy} componentsOrder={componentsOrder} />
                            <Monitors handleTabChange={handleTabChange} />
                            <section className="companies_sec">
                                <div className="row mt-4 justify-content-center">
                                    <div className="col-lg-1">
                                        <img src={thumbnail_logo} className="img-fluid" width="100" height="42" alt="logo" />
                                    </div>
                                    <div className="col-lg-4">
                                        <h5 className="fw-semibold mb-0">{company?.data?.name ? `${company?.data?.name} ESG Risk Rating Rating Overview` : <ShimmerTitle line={1} gap={10} variant="primary" />} </h5>
                                        <div className="d-flex mt-3 text-secondary">
                                            <span className="mr-4">Sector : {company?.data?.category?.parent?.name || "--"} </span>,
                                            <span className="ml-4">Subsector : {company?.data?.category?.name || "--"}</span>
                                        </div>
                                        <span className="mt-2 text-secondary">Update On : {formatDate(company?.data?.updatedAt) || "--"}</span>

                                    </div>
                                    <div className="col-lg-7 text-end">
                                        <button className="companies_report_button_outline rounded-pill">Download Executive
                                            Summary</button><br />
                                        <button className="companies_report_button rounded-pill mt-3">Download Full Report</button>
                                    </div>
                                    <div className="row mt-4 mt-lg-5">
                                        {
                                            page === '' && <>
                                                <div className="col-lg-3">
                                                    <div className="accordion" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header">
                                                                <button className="accordion-button font-s-16 fw-semibold text-black"
                                                                    type="button" data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseOne" aria-expanded="true"
                                                                    aria-controls="collapseOne">
                                                                    Company Overview
                                                                </button>
                                                            </h2>
                                                            <div id="collapseOne" className="accordion-collapse collapse show"
                                                                data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">
                                                                    <h6 className="font-s-16 fw-semibold text_purple">ESG Risk Rating</h6>
                                                                    <ul className="">
                                                                        <li className="active"><Link to={"#"}>Overview</Link></li>
                                                                        <li><Link to={"#"}>Corporate Governance</Link></li>
                                                                        <li><Link to={"#"}>Resource Use</Link></li>
                                                                        <li><Link to={"#"}>E&S Impact of Products and Services</Link></li>
                                                                        <li><Link to={"#"}>Human Capital</Link></li>
                                                                        <li><Link to={"#"}>Product Governance</Link></li>
                                                                        <li><Link to={"#"}>Carbon - Own Operations</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-9">
                                                    <div className="companies_sec">
                                                        <RiskRating lastUpdate={company?.data?.updatedAt} />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="col-lg-12">
                                            <div className="companies_sec">
                                                {page === 'patent' && <PatentRow />}
                                                {page === 'jobs' && <JobRow />}
                                                {page === 'deals' && <DealsRow />}
                                                {page === 'news' && <NewsRow />}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </Layout >
        </>
    )
}

export default CompanyDetail;
