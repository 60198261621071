import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store"; // Assuming RootState is defined in ../../store

function Layout({ children }){
    const { layoutStyle }: { layoutStyle: string } = useSelector((state: RootState) => state.common); // Ensure type inference for useSelector

    const [layoutHover, setLayoutHover] = useState<string>(''); // Explicitly define the type for state variables

    useEffect(() => {
        setTimeout(() => {
            if (layoutStyle === "collapsed") {
                setLayoutHover('active');
            } else {
                setLayoutHover('default');
            }
        }, 500);
    }, [layoutStyle]);

    return (
        <>
            <div className="hk-wrapper hz_layout_2" data-layout="vertical" data-layout-style={layoutStyle} data-menu="light" data-footer="simple" data-hover={layoutHover}>
                <Header />
                <Sidebar />
                <div id="hk_menu_backdrop" className="hk-menu-backdrop"></div>
                {children}
            </div>
        </>
    );
}

export default Layout;
