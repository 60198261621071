 import React from "react";
function Monitors({handleTabChange}) {

    return (
        <section>
        <div className="row mt-4">
            <div className="col-12">
                <div className="companies_filter border bg-info-subtle rounded p-4">
                    <div className="col-12">
                        <h5 className="text-black fw-semibold mb-3"> Monitors</h5>
                    </div>
                    <div className="row">
                    <div className="col-lg">
                            <button className="companies_monitors_button w-100"  onClick={()=>handleTabChange('')}>Overview</button>
                        </div>
                        <div className="col-lg">
                            <button className="companies_monitors_button w-100"  onClick={()=>handleTabChange('patent')}>Patents</button>
                        </div>
                        <div className="col-lg col-md-4 col-sm-6 mt-3 mt-lg-0">
                            <button className="companies_monitors_button w-100" onClick={()=>handleTabChange('deals')}>Deals</button>
                        </div>
                        {/* <div className="col-lg col-md-4 col-sm-6 mt-3 mt-lg-0">
                            <button className="companies_monitors_button w-100">Social Media</button>
                        </div>
                        <div className="col-lg col-md-4 col-sm-6 mt-3 mt-lg-0">
                            <button className="companies_monitors_button w-100">SDG Alignment</button>
                        </div> */}
                        <div className="col-lg col-md-4 col-sm-6 mt-3 mt-lg-0">
                            <button className="companies_monitors_button w-100" onClick={()=>handleTabChange('news')}>News</button>
                        </div>
                        <div className="col-lg col-md-4 col-sm-6 mt-3 mt-lg-0">
                            <button className="companies_monitors_button w-100" onClick={()=>handleTabChange('jobs')}>Jobs</button>
                        </div>
                        {/* <div className="col-lg col-md-4 col-sm-6 mt-3 mt-lg-0">
                            <button className="companies_monitors_button w-100" onClick={()=>handleTabChange('regulations')}>Regulations</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
    
}

export default Monitors